import { Component } from '@angular/core';
import { AuthenticationService } from './service/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Procrea-rencontres-web-hebdomadaires';

  constructor(
    private router: Router,
    private authService: AuthenticationService) {
  }





}


