import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../service/authentication.service';

@Component({
  selector: 'app-dash-left-menu',
  templateUrl: './dash-left-menu.component.html',
  styleUrls: ['./dash-left-menu.component.css']
})
export class DashLeftMenuComponent implements OnInit {

  Usernom = localStorage.getItem('nom');
  Userprenoms = localStorage.getItem('prenoms');
  utilprofl = localStorage.getItem('utilprofl');

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
  
  }



}
