import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profi-create',
  templateUrl: './profi-create.component.html',
  styleUrls: ['./profi-create.component.css']
})
export class ProfiCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
