import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../service/notification.service';
import { WebinarService } from '../../service/webinar.service';

@Component({
  selector: 'app-webi-create',
  templateUrl: './webi-create.component.html',
  styleUrls: ['./webi-create.component.css']
})
export class WebiCreateComponent implements OnInit {

  isLoadingResults = true;
  Title: string = 'Edition d\'une Rencontre Web Hebdomadaire';
  lienRetour: string;

  validedForm: FormGroup;
  IDParticipation: any;
  IDEvent: any = 1;
  partcipantData: any;
  ptcetat: any;
  UserID = localStorage.getItem('UserID');
  public date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private webinarService: WebinarService,
    private notifyService: NotificationService,
    private datePipe: DatePipe,
    private actRoute: ActivatedRoute) {
      this.validedForm = this.fb.group({
        webi_theme: ['', Validators.required],
        webi_desciption: ['', Validators.required],
        webi_date1: ['', Validators.required],
        webi_date2: [''],
        webi_contact1: ['', Validators.required],
        webi_contact2: [''],
        webi_email1: ['', Validators.required],
        webi_email2: [''],
        webi_slogan: [''],
        webi_youtube: [''],
        webi_image: ['', Validators.required],
        webi_linezoom: ['', Validators.required],
        webi_etat: [''],
        webi_datecreate: [''],
        Util_id: [this.UserID],
      });
     }

  ngOnInit(): void {
    this.isLoadingResults = false;
    this.IDParticipation = this.actRoute.snapshot.params['id'];
    this.lienRetour = this.actRoute.snapshot.params['lien'];
   /* this.loadPaticipantBy(this.IDParticipation);*/
  }

  
 /* loadPaticipantBy(id){
    this.validedForm.disable();
    this.inscriptionService.getPaticipantBy(id).subscribe(data => {
    this.partcipantData = data;
   
    this.validedForm.controls['IDParticipation'].setValue(this.IDParticipation);
    this.validedForm.controls['ptc_civilite'].setValue(this.partcipantData.results.liste[0].ptc_civilite);
    this.validedForm.controls['ptc_sexe'].setValue(this.partcipantData.results.liste[0].ptc_sexe);
    this.validedForm.controls['ptc_smatrimoniale'].setValue(this.partcipantData.results.liste[0].ptc_smatrimoniale);
    this.validedForm.controls['ptc_Nom'].setValue(this.partcipantData.results.liste[0].ptc_Nom);
    this.validedForm.controls['ptc_Prenoms'].setValue(this.partcipantData.results.liste[0].ptc_Prenoms);
    this.validedForm.controls['ptc_NomJeunefille'].setValue(this.partcipantData.results.liste[0].ptc_NomJeunefille);
    this.validedForm.controls['ptc_Pays'].setValue(this.partcipantData.results.liste[0].ptc_Pays);
    this.validedForm.controls['ptc_Ville'].setValue(this.partcipantData.results.liste[0].ptc_Ville);
    this.validedForm.controls['ptc_Datenaissance'].setValue(this.partcipantData.results.liste[0].ptc_Datenaissance);
    this.validedForm.controls['ptc_Email'].setValue(this.partcipantData.results.liste[0].ptc_Email);
    this.validedForm.controls['ptc_Mobile'].setValue(this.partcipantData.results.liste[0].ptc_Mobile);
    this.validedForm.controls['ptc_recevMessaeg'].setValue(this.partcipantData.results.liste[0].ptc_recevMessaeg);
    this.ptcetat = this.partcipantData.results.liste[0].ptc_etat;
    this.isLoadingResults = false;
    });
    }*/

    saveForm(value){
      this.isLoadingResults = true;
      const ValidedFormData = new FormData();
      ValidedFormData.append('webi_theme', value.webi_theme);
      ValidedFormData.append('webi_desciption', value.webi_desciption);
      ValidedFormData.append('webi_date1', value.webi_date1);
      ValidedFormData.append('webi_date2', value.webi_date2);
      ValidedFormData.append('webi_contact1', value.webi_contact1);
      ValidedFormData.append('webi_contact2', value.webi_contact2);
      ValidedFormData.append('webi_email1', value.webi_email1);
      ValidedFormData.append('webi_email2', value.webi_email2);
      ValidedFormData.append('webi_slogan', value.webi_slogan);
      ValidedFormData.append('webi_youtube', value.webi_youtube);
      ValidedFormData.append('webi_image', this.validedForm.get('webi_image').value);
      ValidedFormData.append('webi_linezoom', value.webi_linezoom);
      ValidedFormData.append('webi_etat', value.webi_etat);
      ValidedFormData.append('util_id', this.UserID);
      ValidedFormData.append('webi_datecreate', this.date);
    
      this.webinarService.createWebinar(ValidedFormData).subscribe(result => {
        if (result.success === true) {
       this.notifyService.showSuccess(result.message, 'SUCCES');
       this.isLoadingResults = false;
       this.validedForm.reset();
       this.getNavigation(this.lienRetour,'');
            }
            else {
              this.notifyService.showError(result.message, 'MESSAGE D\'ERREUR');
              this.isLoadingResults = false;
            }
   });
  }


  onFile(event) {
    if (event.target.files.length > 0) {
      const fileannx = event.target.files[0];
      this.validedForm.get('webi_image').setValue(fileannx);
    }
  }

  getNavigation(link, id){
    if(id === ''){
        this.router.navigate([link]);
    } else {
        this.router.navigate([link + '/' + id]);
    }
}

}
