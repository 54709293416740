import { Component, OnInit } from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { AuthenticationService } from '../service/authentication.service';
import {Router} from '@angular/router';
import { NotificationService } from '../service/notification.service';

@Component({
  selector: 'app-login-reset',
  templateUrl: './login-reset.component.html',
  styleUrls: ['./login-reset.component.css']
})
export class LoginResetComponent implements OnInit {
getlogin = localStorage.getItem('login');
  ValidedForm: FormGroup;
    isLoadingResults = false;
    constructor(
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private notifyService: NotificationService,) { 
  this.ValidedForm = this.fb.group({
    oldmotdepasse: ['', Validators.required],
    newmotdepasse: ['', Validators.required],
    renewmotdepasse: ['', Validators.required],
    login: [''],
  });
}

ngOnInit() {
/*this.authenticationService.logout();*/
}

login(values) {
this.isLoadingResults = true;
const ValidedFormData = new FormData();
ValidedFormData.append('login', this.getlogin);
ValidedFormData.append('oldmotdepasse', values.oldmotdepasse);
ValidedFormData.append('newmotdepasse', values.newmotdepasse);
ValidedFormData.append('renewmotdepasse', values.renewmotdepasse);

this.authenticationService.PremiereConnexion(ValidedFormData).subscribe(result => {
  if (result.success === true) {
 this.notifyService.showSuccess(result.message, 'SUCCES');
 this.isLoadingResults = false;
 this.router.navigate(['/dashboard']);
      }
      else {
        this.notifyService.showError(result.message, 'MESSAGE D\'ERREUR');
        this.isLoadingResults = false;
      }
});




}

}
