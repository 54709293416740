import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../service/notification.service';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.css']
})
export class UserUpdateComponent implements OnInit {



  isLoadingResults = true;
  Title: string = 'Edition d\'un utilisateur';
  lienRetour: string;
  statusbtn: boolean = false;
  validedForm: FormGroup;
  IDParticipation: any;
  utilId: any;
  partcipantData: any;
  ptcetat: any;
  userData: any;
  UserID = localStorage.getItem('UserID');
  public date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private notifyService: NotificationService,
    private datePipe: DatePipe,
    private actRoute: ActivatedRoute) {
      this.validedForm = this.fb.group({
        util_Nom: ['', Validators.required],
        util_Prenoms: ['', Validators.required],
        util_Email: ['', Validators.required],
        util_Login: ['', Validators.required],
        util_DateCrea: [''],
        utilId: [''],
        util_profl: ['', Validators.required],
     
        Util_id: [this.UserID],
      });
     }

  ngOnInit(): void {
    this.isLoadingResults = false;
    this.utilId = this.actRoute.snapshot.params['id'];
    /* this.lienRetour = this.actRoute.snapshot.params['lien'];*/
    this.loaduserBy(this.utilId);
  }


  
 loaduserBy(id){
  this.isLoadingResults = true;
  this.userService.getUserbyId(id).subscribe(data => {
  this.userData = data;
  this.validedForm.controls['utilId'].setValue(this.utilId);
  this.validedForm.controls['util_Nom'].setValue(this.userData.results.liste[0].util_Nom);
  this.validedForm.controls['util_Prenoms'].setValue(this.userData.results.liste[0].util_Prenoms);
  this.validedForm.controls['util_Email'].setValue(this.userData.results.liste[0].util_Email);
  this.validedForm.controls['util_Login'].setValue(this.userData.results.liste[0].util_Login);
  this.validedForm.controls['util_profl'].setValue(this.userData.results.liste[0].util_profl);
  
  this.isLoadingResults = false;
  }); 
  }

    saveForm(value){
      this.isLoadingResults = true;
      const ValidedFormData = new FormData();
      ValidedFormData.append('utilId', this.utilId);
      ValidedFormData.append('util_Nom', value.util_Nom);
      ValidedFormData.append('util_Prenoms', value.util_Prenoms);
      ValidedFormData.append('util_Email', value.util_Email);
      ValidedFormData.append('util_Login', value.util_Login);
      ValidedFormData.append('util_profl', value.util_profl);
      ValidedFormData.append('util_id', this.UserID);
     /* ValidedFormData.append('util_DateCrea', this.date);*/
      this.userService.updatetUser(ValidedFormData).subscribe(result => {
        if (result.success === true) { 
       this.notifyService.showSuccess(result.message, 'SUCCES');
       this.isLoadingResults = false;
       this.validedForm.reset();
       this.getNavigation('/users', '');
            }
            else {
              this.notifyService.showError(result.message, 'MESSAGE D\'ERREUR');
              this.isLoadingResults = false;
            }
   });
  }


  onFile(event) {
    if (event.target.files.length > 0) {
      const fileannx = event.target.files[0];
      this.validedForm.get('webi_image').setValue(fileannx);
    }
  }

  getNavigation(link, id){
    if(id === ''){
        this.router.navigate([link]);
    } else {
        this.router.navigate([link + '/' + id]);
    }
}



}
