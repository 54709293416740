import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from '../service/notification.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { InscriptionService } from '../service/inscription.service';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
public date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
Usernom = localStorage.getItem('nom');
Userprenoms = localStorage.getItem('prenoms');

Title: string = 'Tableau de bord | Webinar en cours...';
lienRetour: string = 'dashboard';
imprimTitle: string = 'Inscription en attente de validation';
utilprofl = localStorage.getItem('utilprofl');
     /*/////DELETE CONFIRMATION///////*/
     public popoverTitle: string = 'AVERTISSEMENT';
     public popoverMessage: string = 'Voulez-vous vraiment supprimer cette ligne?';
     public confirmClicked: boolean = false;
     public cancelClicked: boolean = false;
   /*/////END DELETE CONFIRMATION///////*/

validedForm: FormGroup;
tableData$: any = [];
isLoadingResults = true;
totalInscrits: string;
totalvalides: string;
totalrejetes: string;
idpays: string = '0';
allpays: any = [];
Printparticipant: any = [];
Allparticipant: any = [];
pdebut: string;
pfin: string;
webitheme: string;

displayedColumns: string[] = ['position', 'nomprenom', 'pays', 'sexe', 'contact', 'Action'];
 dataSource = new MatTableDataSource(this.tableData$);
 @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

 /*********EXPORTATION EN EXCELL******************** */
 @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
 title = 'Excel';
 ExportTOExcel() {
   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
   XLSX.writeFile(wb, '' + this.date + ' ' + 'WEBINAIRE PROCREA LISTE DES INSCRITS.xlsx');
 }
/*********EXPORTATION EN EXCELL******************** */
  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private inscriptionService: InscriptionService,
    private notificationService: NotificationService,
    private fb: FormBuilder) {
    this.validedForm = this.fb.group({
      ptc_Pays: [''],
      sexe: [''],
      datedebut: [this.date, Validators.required],
      datefin: [this.date, Validators.required],

    });
   }

  ngOnInit(): void {
    this.pfin = '0';
    this.loadPayswebinarActif(this.idpays);
    this.loadDataTable();
    this.dataSource.paginator = this.paginator;
    this.loadtotalParticipant();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

    loadDataTable() {
    this.isLoadingResults = true;
    this.inscriptionService.getallPaticipant().subscribe(data => {
      this.tableData$ = data;
      this.totalInscrits = this.tableData$.results.total;
      this.dataSource.data = this.tableData$.results.liste;
      this.Printparticipant = this.tableData$.results.liste;
      this.webitheme = this.tableData$.results.liste[0].webi_theme;
      this.loadtotalvalide();
      this.loadtotalrejete();
      this.isLoadingResults = false;
    });  /**/
  }

  loadtotalParticipant() {
    this.inscriptionService.getTouslesPaticipants().subscribe(data => {
      this.tableData$ = data;
      this.Allparticipant = this.tableData$.results.liste;

    });  /**/
  }

  loadtotalvalide() {
    this.inscriptionService.getallPaticipantValider(1).subscribe(data => {
      this.tableData$ = data;
      this.totalvalides = this.tableData$.results.total;

    });  /**/
  }

  loadtotalrejete() {
    this.inscriptionService.getallPaticipantRejete(2).subscribe(data => {
      this.tableData$ = data;
      this.totalrejetes = this.tableData$.results.total;
    });  /**/
  }

  search(values){
    this.isLoadingResults = true;
    const ValidedFormData = new FormData();
    ValidedFormData.append('ptc_Pays', values.ptc_Pays);
    ValidedFormData.append('sexe', values.sexe);
    ValidedFormData.append('datefin', values.datefin);
    ValidedFormData.append('datedebut', values.datedebut);
    this.inscriptionService.searchPaticipant(ValidedFormData).subscribe(data => {
      this.tableData$ = data;
      this.totalInscrits = this.tableData$.results.total;
      this.dataSource.data = this.tableData$.results.liste;
      this.loadtotalvalide();
      this.loadtotalrejete();
      this.isLoadingResults = false;
      this.Printparticipant = this.tableData$.results.liste;
      this.pdebut =  values.datedebut;
      this.pfin = values.datefin;
    });
  }


  Delete(ID) {
    this.isLoadingResults = true;
    this.inscriptionService.deletePaticipant(ID).subscribe(data => {
      if (data.success === true) {
        this.notificationService.showSuccess(data.message, 'SUCCES');
        this.loadDataTable();
        this.isLoadingResults = false;
      }else {
        this.notificationService.showError(data.message, 'MESSAGE D\'ERREUR');
        this.isLoadingResults = false;
      }
       });
      }

  getNavigation(link, id){
    if(id === ''){
        this.router.navigate([link]);
    } else {
        this.router.navigate([link + '/' + id]);
    }
}

loadPayswebinarActif(id){
  this.inscriptionService.getPaticipantPays(id).subscribe(
  paysData => {
    this.allpays = paysData.results.liste;
  
           }
  );
}

/*onChangeSelectedValue(event){
  const selectEl = event.target;
  const pays = selectEl.options[selectEl.selectedIndex].getAttribute('name-pays');
  const sexe = selectEl.options[selectEl.selectedIndex].getAttribute('name-sexe');
  this.seachParticipantbyPays(pays);
} */



/*seachParticipantbyPays(pays) {
  this.isLoadingResults = true;
  this.inscriptionService.getallPaticipantByPays(pays).subscribe(data => {
    this.tableData$ = data;
    this.totalInscrits = this.tableData$.results.total;
    this.dataSource.data = this.tableData$.results.liste;
    this.loadtotalvalide();
    this.loadtotalrejete();
    this.isLoadingResults = false;
  });  
}
*/

getNavigationRetun(link, id, lien){
  if(id === ''){
      this.router.navigate([lien]);
  } else {
      this.router.navigate([link + '/' + id + '/' + lien ]);
  }
}

}
