
<!--app-slider></app-slider-->
<section id="slider-part">
    <div class="single-slider">
        <div class="overlay2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="slider-text1">
                            <p>Rencontres Web Hebdomadaires</p>
                            <h2>{{theme}}</h2>
                        </div>
                        <div class="spinner4" *ngIf="isLoadingResults">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                        <div class="row d-flex align-items-center justify-content-lg-end justify-content-center">
                            <div class="col-lg-3">
                                <div class="myCountdown2 d-flex align-items-center justify-content-lg-end justify-content-center" data-date="2021-3-28 23:59:59">
                                    <div class="counter-item2 item-title2">
                                        <h2>{{slogan}}</h2>
                                       
                                    </div>
                              
                                </div>
                            </div>
                            <div class="col-lg-7" *ngIf="lientemoignage!=''">
                                <div class="video-part2 d-flex align-items-center justify-content-end" style="padding-right: 70px;">
                                    <p>Voir le témoignage d'une patiente</p>
                                    <a class="venobox vbox-item" data-autoplay="true" data-vbtype="video" href=" {{lientemoignage}}"><i class="fa fa-play" aria-hidden="true"></i></a>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                       <!--=================
     booking-part start
 ==================-->
 <section id="pricing-part2">
    <div class="booking-part">
     <div class="container">
         <div class="row">
             <div class="col-lg-12">
                 <div class="form-part2">
                     <form [formGroup]="validedForm" (ngSubmit)="saveForm(validedForm.value)">
                         <label class="heading2 f-heading">Formulaire d'inscription</label>
                    <input type="text" hidden formControlName="IDEvent" placeholder="IDEvent">                                
                    <input type="text" hidden formControlName="IDParticipation" placeholder="IDParticipation">

                    <select name="pays" formControlName="ptc_civilite" class="selection" style="width: 31.4%;  margin-right: 2.7%;">
                        <option value=""> Civilité * </option>
                         <option value="Mr">Mr</option>
                         <option value="Mme">Mme</option>
                         <option value="Mlle">Mlle</option>
                     </select>

                     <select name="ptc_sexe" formControlName="ptc_sexe" style="width: 31.4%; margin-right: 2.7%;">
                        <option value=""> Sexe * </option>
                         <option value="M">Masculin</option>
                         <option value="F">Féminin</option>
                     </select>

                     <select name="ptc_smatrimoniale" formControlName="ptc_smatrimoniale" style="width: 31.4%;">
                        <option value=""> Situation matrimoniale * </option>
                         <option value="celibataire">Célibataire</option>
                         <option value="mariee">Mariée</option>
                         <option value="veuve">Veuve</option>
                         <option value="divorcee">Divorcée</option>
                     </select>
                    
                    <input type="text" formControlName="ptc_Nom" style="width: 31.4%; margin-right: 2.7%;" placeholder="Votre nom *">

                    <input type="text" formControlName="ptc_Prenoms" style="width: 31.4%; margin-right: 2.7%;" placeholder="Votre prénom *">

                     <input type="text" formControlName="ptc_NomJeunefille" style="width: 31.4%;" placeholder="Votre nom de jeune fille">

                     <select name="pays" formControlName="ptc_Pays" style="width: 31.4%; margin-right: 2.7%;">
                            <option value="Cote_d_Ivoire" selected="selected">Côte d'Ivoire </option>
                             <option value="Afghanistan">Afghanistan </option>
                            <option value="Afrique_Centrale">Afrique Centrale </option>
                            <option value="Afrique_du_sud">Afrique du Sud </option>
                            <option value="Albanie">Albanie </option>
                            <option value="Algerie">Algerie </option>
                            <option value="Allemagne">Allemagne </option>
                            <option value="Andorre">Andorre </option>
                            <option value="Angola">Angola </option>
                            <option value="Anguilla">Anguilla </option>
                            <option value="Arabie_Saoudite">Arabie Saoudite </option>
                            <option value="Argentine">Argentine </option>
                            <option value="Armenie">Armenie </option>
                            <option value="Australie">Australie </option>
                            <option value="Autriche">Autriche </option>
                            <option value="Azerbaidjan">Azerbaidjan </option>
                            
                            <option value="Bahamas">Bahamas </option>
                            <option value="Bangladesh">Bangladesh </option>
                            <option value="Barbade">Barbade </option>
                            <option value="Bahrein">Bahrein </option>
                            <option value="Belgique">Belgique </option>
                            <option value="Belize">Belize </option>
                            <option value="Benin">Benin </option>
                            <option value="Bermudes">Bermudes </option>
                            <option value="Bielorussie">Bielorussie </option>
                            <option value="Bolivie">Bolivie </option>
                            <option value="Botswana">Botswana </option>
                            <option value="Bhoutan">Bhoutan </option>
                            <option value="Boznie_Herzegovine">Boznie Herzegovine </option>
                            <option value="Bresil">Bresil </option>
                            <option value="Brunei">Brunei </option>
                            <option value="Bulgarie">Bulgarie </option>
                            <option value="Burkina_Faso">Burkina Faso </option>
                            <option value="Burundi">Burundi </option>
                            
                            <option value="Caiman">Caiman </option>
                            <option value="Cambodge">Cambodge </option>
                            <option value="Cameroun">Cameroun </option>
                            <option value="Canada">Canada </option>
                            <option value="Canaries">Canaries </option>
                            <option value="Cap_vert">Cap Vert </option>
                            <option value="Chili">Chili </option>
                            <option value="Chine">Chine </option>
                            <option value="Chypre">Chypre </option>
                            <option value="Colombie">Colombie </option>
                            <option value="Comores">Colombie </option>
                            <option value="Congo">Congo </option>
                            <option value="Congo_democratique">Congo democratique </option>
                            <option value="Cook">Cook </option>
                            <option value="Coree_du_Nord">Coree du Nord </option>
                            <option value="Coree_du_Sud">Coree_du_Sud </option>
                            <option value="Costa_Rica">Costa_Rica </option>
                            <option value="Cote_d_Ivoire">Côte d'Ivoire </option>
                            <option value="Croatie">Croatie </option>
                            <option value="Cuba">Cuba </option>
                            
                            <option value="Danemark">Danemark </option>
                            <option value="Djibouti">Djibouti </option>
                            <option value="Dominique">Dominique </option>
                            
                            <option value="Egypte">Egypte </option>
                            <option value="Emirats_Arabes_Unis">Emirats Arabes Unis </option>
                            <option value="Equateur">Equateur </option>
                            <option value="Erythree">Erythree </option>
                            <option value="Espagne">Espagne </option>
                            <option value="Estonie">Estonie </option>
                            <option value="Etats_Unis">Etats Unis </option>
                            <option value="Ethiopie">Ethiopie </option>
                            
                            <option value="Falkland">Falkland </option>
                            <option value="Feroe">Feroe </option>
                            <option value="Fidji">Fidji </option>
                            <option value="Finlande">Finlande </option>
                            <option value="France">France </option>
                            
                            <option value="Gabon">Gabon </option>
                            <option value="Gambie">Gambie </option>
                            <option value="Georgie">Georgie </option>
                            <option value="Ghana">Ghana </option>
                            <option value="Gibraltar">Gibraltar </option>
                            <option value="Grece">Grece </option>
                            <option value="Grenade">Grenade </option>
                            <option value="Groenland">Groenland </option>
                            <option value="Guadeloupe">Guadeloupe </option>
                            <option value="Guam">Guam </option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernesey">Guernesey </option>
                            <option value="Guinee">Guinee </option>
                            <option value="Guinee_Bissau">Guinee_Bissau </option>
                            <option value="Guinee equatoriale">Guinee Equatoriale </option>
                            <option value="Guyana">Guyana </option>
                            <option value="Guyane_Francaise ">Guyane Francaise </option>
                            
                            <option value="Haiti">Haiti </option>
                            <option value="Hawaii">Hawaii </option>
                            <option value="Honduras">Honduras </option>
                            <option value="Hong_Kong">Hong Kong </option>
                            <option value="Hongrie">Hongrie </option>
                            
                            <option value="Inde">Inde </option>
                            <option value="Indonesie">Indonesie </option>
                            <option value="Iran">Iran </option>
                            <option value="Iraq">Iraq </option>
                            <option value="Irlande">Irlande </option>
                            <option value="Islande">Islande </option>
                            <option value="Israel">Israel </option>
                            <option value="Italie">italie </option>
                            
                            <option value="Jamaique">Jamaique </option>
                            <option value="Jan Mayen">Jan Mayen </option>
                            <option value="Japon">Japon </option>
                            <option value="Jersey">Jersey </option>
                            <option value="Jordanie">Jordanie </option>
                            
                            <option value="Kazakhstan">Kazakhstan </option>
                            <option value="Kenya">Kenya </option>
                            <option value="Kirghizstan">Kirghizistan </option>
                            <option value="Kiribati">Kiribati </option>
                            <option value="Koweit">Koweit </option>
                            
                            <option value="Laos">Laos </option>
                            <option value="Lesotho">Lesotho </option>
                            <option value="Lettonie">Lettonie </option>
                            <option value="Liban">Liban </option>
                            <option value="Liberia">Liberia </option>
                            <option value="Liechtenstein">Liechtenstein </option>
                            <option value="Lituanie">Lituanie </option>
                            <option value="Luxembourg">Luxembourg </option>
                            <option value="Lybie">Lybie </option>
                            
                            <option value="Macao">Macao </option>
                            <option value="Macedoine">Macedoine </option>
                            <option value="Madagascar">Madagascar </option>
                            <option value="Madère">Madère </option>
                            <option value="Malaisie">Malaisie </option>
                            <option value="Malawi">Malawi </option>
                            <option value="Maldives">Maldives </option>
                            <option value="Mali">Mali </option>
                            <option value="Malte">Malte </option>
                            <option value="Man">Man </option>
                            <option value="Mariannes du Nord">Mariannes du Nord </option>
                            <option value="Maroc">Maroc </option>
                            <option value="Marshall">Marshall </option>
                            <option value="Martinique">Martinique </option>
                            <option value="Maurice">Maurice </option>
                            <option value="Mauritanie">Mauritanie </option>
                            <option value="Mayotte">Mayotte </option>
                            <option value="Mexique">Mexique </option>
                            <option value="Micronesie">Micronesie </option>
                            <option value="Midway">Midway </option>
                            <option value="Moldavie">Moldavie </option>
                            <option value="Monaco">Monaco </option>
                            <option value="Mongolie">Mongolie </option>
                            <option value="Montserrat">Montserrat </option>
                            <option value="Mozambique">Mozambique </option>
                            
                            <option value="Namibie">Namibie </option>
                            <option value="Nauru">Nauru </option>
                            <option value="Nepal">Nepal </option>
                            <option value="Nicaragua">Nicaragua </option>
                            <option value="Niger">Niger </option>
                            <option value="Nigeria">Nigeria </option>
                            <option value="Niue">Niue </option>
                            <option value="Norfolk">Norfolk </option>
                            <option value="Norvege">Norvege </option>
                            <option value="Nouvelle_Caledonie">Nouvelle_Caledonie </option>
                            <option value="Nouvelle_Zelande">Nouvelle Zelande </option>
                            
                            <option value="Oman">Oman </option>
                            <option value="Ouganda">Ouganda </option>
                            <option value="Ouzbekistan">Ouzbekistan </option>
                            
                            <option value="Pakistan">Pakistan </option>
                            <option value="Palau">Palau </option>
                            <option value="Palestine">Palestine </option>
                            <option value="Panama">Panama </option>
                            <option value="Papouasie_Nouvelle_Guinee">Papouasie Nouvelle Guinee </option>
                            <option value="Paraguay">Paraguay </option>
                            <option value="Pays_Bas">Pays_Bas </option>
                            <option value="Perou">Perou </option>
                            <option value="Philippines">Philippines </option>
                            <option value="Pologne">Pologne </option>
                            <option value="Polynesie">Polynesie </option>
                            <option value="Porto_Rico">Porto_Rico </option>
                            <option value="Portugal">Portugal </option>
                            
                            <option value="Qatar">Qatar </option>
                            
                            <option value="Republique_Dominicaine">Republique Dominicaine </option>
                            <option value="Republique_Tcheque">Republique_Tcheque </option>
                            <option value="Reunion">Reunion </option>
                            <option value="Roumanie">Roumanie </option>
                            <option value="Royaume_Uni">Royaume Uni </option>
                            <option value="Russie">Russie </option>
                            <option value="Rwanda">Rwanda </option>
                            
                            <option value="Sahara Occidental">Sahara Occidental </option>
                            <option value="Sainte_Lucie">Sainte Lucie </option>
                            <option value="Saint_Marin">Saint_Marin </option>
                            <option value="Salomon">Salomon </option>
                            <option value="Salvador">Salvador </option>
                            <option value="Samoa_Occidentales">Samoa Occidentales</option>
                            <option value="Samoa_Americaine">Samoa_Americaine </option>
                            <option value="Sao_Tome_et_Principe">Sao Tome et Principe </option>
                            <option value="Senegal">Senegal </option>
                            <option value="Seychelles">Seychelles </option>
                            <option value="Sierra Leone">Sierra Leone </option>
                            <option value="Singapour">Singapour </option>
                            <option value="Slovaquie">Slovaquie </option>
                            <option value="Slovenie">Slovenie</option>
                            <option value="Somalie">Somalie </option>
                            <option value="Soudan">Soudan </option>
                            <option value="Sri_Lanka">Sri Lanka </option>
                            <option value="Suede">Suede </option>
                            <option value="Suisse">Suisse </option>
                            <option value="Surinam">Surinam </option>
                            <option value="Swaziland">Swaziland </option>
                            <option value="Syrie">Syrie </option>
                            
                            <option value="Tadjikistan">Tadjikistan </option>
                            <option value="Taiwan">Taiwan </option>
                            <option value="Tonga">Tonga </option>
                            <option value="Tanzanie">Tanzanie </option>
                            <option value="Tchad">Tchad </option>
                            <option value="Thailande">Thailande </option>
                            <option value="Tibet">Tibet </option>
                            <option value="Timor_Oriental">Timor Oriental </option>
                            <option value="Togo">Togo </option>
                            <option value="Trinite_et_Tobago">Trinite et Tobago </option>
                            <option value="Tristan da cunha">Tristan de cuncha </option>
                            <option value="Tunisie">Tunisie </option>
                            <option value="Turkmenistan">Turmenistan </option>
                            <option value="Turquie">Turquie </option>
                            
                            <option value="Ukraine">Ukraine </option>
                            <option value="Uruguay">Uruguay </option>
                            
                            <option value="Vanuatu">Vanuatu </option>
                            <option value="Vatican">Vatican </option>
                            <option value="Venezuela">Venezuela </option>
                            <option value="Vierges_Americaines">Vierges Americaines </option>
                            <option value="Vierges_Britanniques">Vierges Britanniques </option>
                            <option value="Vietnam">Vietnam </option>
                            
                            <option value="Wake">Wake </option>
                            <option value="Wallis et Futuma">Wallis et Futuma </option>
                            
                            <option value="Yemen">Yemen </option>
                            <option value="Yougoslavie">Yougoslavie </option>
                            
                            <option value="Zambie">Zambie </option>
                            <option value="Zimbabwe">Zimbabwe </option>
                            
                            </select>
                    <input placeholder="Ville *" formControlName="ptc_Ville" type="text" id="date" style="width: 31.4%; margin-right: 2.7%;">
                     
                             
                     <input placeholder="Date de naissance *" formControlName="ptc_Datenaissance" class="textbox-n" type="text" onfocus="(this.type='date')" onblur="(this.type='text')" id="date" style="width: 31.4%;">
                     
                    

                    <input type="email" formControlName="ptc_Email" class="form-control is-invalid" placeholder="Adresse E-mail *">
                       
                    <input type="text" formControlName="ptc_Mobile" class="form-control is-invalid" placeholder="Téléphone *">
                    <label class="check2"><input type="checkbox" name="ptc_recevMessaeg" [ngModel]="1" formControlName="ptc_recevMessaeg" class="checkbtn" id="ptc_Mobile"> Je consens à recevoir des messages électroniques (informations, invitations, offres) de la part de la Clinique PROCREA.</label>
                         <button type="submit" class="spinner-border text-dark" [disabled]="!validedForm.valid" [disabled]="statusbtn" style="text-transform: uppercase;"><span *ngIf="statusbtn; else notShow"><i class='fa fa-circle-o-notch fa-spin'></i> Traitement en cours, veuillez patienter svp! </span> 
                            
                        </button>
                         <ng-template #notShow>
                            <span >JE M'INSCRIS</span>
                          </ng-template>
                     </form>
                 </div>
             </div>
         </div>
       
     </div>
     </div>
 </section>

                    </div>
                </div>
              
            </div>
        </div>
    </div>
</section>



    <!--=================
        festive-part start
    ==================-->
    <section id="festive-part" style="padding:50px;">
        <div class="spinner4" *ngIf="isLoadingResults">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                   
                    <!--div class="festive-left"></div-->
                        <div>
                            <div  data-slick-index="1" aria-hidden="true" tabindex="0" role="tabpanel" id="slick-slide01" aria-describedby="slick-slide-control01" style="width: 545px;">
                                <a class="venobox vbox-item" data-autoplay="true" data-gall="myGallery" href="{{webiimage}}">
                   
                                <img class="festive" src="{{webiimage}}" alt="rencontres-web-hebdomadaires"></a>
                            </div>
                  
                     </div>
                    
                </div>
               
                <div class="col-md-6"> 
                    <!--div class="festive-right">  </div--> 
                       <div class="festive-text2">
                            <h2>{{theme}}</h2>
                            <p class="fest-para1">
                                {{webidesciption}}
                             </p>
                             <div>
                                <div style="padding: 5px; font-size: 32px; color: #F26F43;">Session</div>
                                <div style="padding: 20px; font-size: 32px; color: #F26F43;">{{webidate1| date:"dd/MM/yyyy" }} - {{webidate2 | date:"dd/MM/yyyy"}}</div>
                                <div style="padding: 20px;font-size: 32px;color: #F26F43;"></div>
                             </div>                  
                           
                            <div class="location">
                                <div class="address">
                                    <img src="assets/images/media/zoom-web-application.png" alt="zoom-web-application">
                                    <table>
                                        <tr>
                                            <td style="font-size: 18px;" colspan="2">Via l'application ZOOM</td>
                                        </tr>
                                        <tr>
                                            <td style="width: 24px;"><img src="assets/images/application/TDpp7fh8s7a.svg" alt="Android" style="width: 24px;"/></td>
                                            <td><a href="https://play.google.com/store/apps/details?id=us.zoom.videomeetings" target="_blank" style="color:#1EBEA5; margin: 0px;">Android</a></td>
                                        </tr>
                                        <tr>
                                            <td style="width: 24px;"><img src="assets/images/application/QBqjMTNuxPz.svg" alt="iPhone" /></td>
                                            <td><a href="https://apps.apple.com/us/app/zoom-cloud-meetings/id546505307" target="_blank" style="color:#1EBEA5;">iPhone</a></td>
                                        </tr>
                                        <tr>
                                            <td style="width: 24px;"><img src="assets/images/application/w9G2-B0wRmZ.svg" alt="Mac ou PC Windows" /></td>
                                            <td style="color:#1EBEA5;"> <a href="https://apps.apple.com/us/app/id546505307" target="_blank" style="color:#1EBEA5;">Mac</a> ou <a href="https://zoom.us/client/latest/ZoomInstaller.exe" target="_blank" style="color:#1EBEA5;">PC Windows</a></td>
                                        </tr>
                                    </table>
                                                          
                                </div>
                                <div class="address add-ml">
                                    <img src="assets/phone.png" alt="phone">
                                    
                                    <p class="num">{{webicontact1}}  {{webicontact2}}</p>
                                </div>
                            </div>
                            <div>
                                  </div>  
                                                                                  
                              
                           
                        
                        </div>
                     

     
                
                </div>
               
            </div>
           
        </div>



    </section>

    <div class="col-md-12" style="padding-bottom: 20px;">
    <div class="container">
        <div class="row">
                  
            <div class="footer-menu2">
                <ul>    
                    <li>
                        <a class="venobox vbox-item" data-autoplay="true" data-vbtype="video" href="https://www.youtube.com/watch?v=FeB0hjW-tog"><i class="fa fa-play" aria-hidden="true"></i> Comment installer ZOOM sur son ordinateur en 2 MIN</a></li>
                    <li>
                        <a class="venobox vbox-item" data-autoplay="true" data-vbtype="video" href="https://www.youtube.com/watch?v=ASwxEerYcjY"><i class="fa fa-play" aria-hidden="true"></i> Zoom Installation sur iPad & Iphone</a></li>
                    <li>
                        <a class="venobox vbox-item" data-autoplay="true" data-vbtype="video" href="https://www.youtube.com/watch?v=2GqqTyyEybc"><i class="fa fa-play" aria-hidden="true"></i> Zoom Installation sur téléphone Android</a></li>
                     </ul>
            </div>
            </div>
        </div>
    </div>
    <!--=================
        festive-part end
    ==================-->
    


<!--app-festive-part></app-festive-part-->
<!--app-speakers></app-speakers-->
<!--app-upcoming-part></app-upcoming-part>
<app-galerie-part></app-galerie-part-->
<!--app-testimonial-part></app-testimonial-part-->
<!--app-pricing-part></app-pricing-part-->
<!--app-supporters-part></app-supporters-part-->
