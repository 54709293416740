import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { InscriptionService } from '../../service/inscription.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NotificationService } from '../../service/notification.service';

@Component({
  selector: 'app-dash-valider',
  templateUrl: './dash-valider.component.html',
  styleUrls: ['./dash-valider.component.css']
})
export class DashValiderComponent implements OnInit {
  public date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
Usernom = localStorage.getItem('nom');
Userprenoms = localStorage.getItem('prenoms');

  Title: string = 'Inscriptions validées | Webinar en cours...';
  lienRetour: string = 'participant-valider';
  imprimTitle: string = 'Inscriptions validées';
  /*/////DELETE CONFIRMATION///////*/
  public popoverTitle: string = 'AVERTISSEMENT';
  public popoverMessage: string = 'Voulez-vous vraiment supprimer cette ligne?';
  public confirmClicked: boolean = false;
  public cancelClicked: boolean = false;
/*/////END DELETE CONFIRMATION///////*/

validedForm: FormGroup;
tableData$: any = [];
isLoadingResults = true;
totalInscrits: string;
totalvalides: string;
totalrejetes: string;
allpays: any = [];
Printparticipant: any = [];
pdebut: string;
pfin: string;
idpays: string = '0';
webitheme: string;

displayedColumns: string[] = ['position', 'nomprenom', 'pays', 'sexe', 'contact', 'Action'];
dataSource = new MatTableDataSource(this.tableData$);
@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

constructor(
  private router: Router,
  private datePipe: DatePipe,
  private inscriptionService: InscriptionService,
  private notificationService: NotificationService,
  private fb: FormBuilder) {
  this.validedForm = this.fb.group({
    ptc_Pays: [''],
    sexe: [''],
    datedebut: [this.date, Validators.required],
    datefin: [this.date, Validators.required],

  });
 }

ngOnInit(): void {
  this.pfin = '0';
  this.loadPayswebinarActif(this.idpays);
  this.loadDataTable();
  this.dataSource.paginator = this.paginator;
}
 
applyFilter(event: Event) {
 const filterValue = (event.target as HTMLInputElement).value;
 this.dataSource.filter = filterValue.trim().toLowerCase();
}

 loadDataTable() {
 this.isLoadingResults = true;
 this.inscriptionService.getallPaticipantValider(1).subscribe(data => {
   this.tableData$ = data;
   this.totalInscrits = this.tableData$.results.total;
   this.dataSource.data = this.tableData$.results.liste;
   this.webitheme = this.tableData$.results.liste[0].webi_theme;
   this.Printparticipant = this.tableData$.results.liste;
   this.loadtotalvalide();
   /*this.loadtotalrejete();*/

 });  /**/
}

loadtotalvalide() {
 this.inscriptionService.getallPaticipantValider(1).subscribe(data => {
   this.tableData$ = data;
   this.totalvalides = this.tableData$.results.total;
   this.isLoadingResults = false;

 });  /**/
}

search(values){
  this.isLoadingResults = true;
  const ValidedFormData = new FormData();
  ValidedFormData.append('ptc_Pays', values.ptc_Pays);
  ValidedFormData.append('sexe', values.sexe);
  ValidedFormData.append('datefin', values.datefin);
  ValidedFormData.append('datedebut', values.datedebut);
  this.inscriptionService.searchPaticipantValide(ValidedFormData).subscribe(data => {
    this.tableData$ = data;
    this.totalInscrits = this.tableData$.results.total;
    this.dataSource.data = this.tableData$.results.liste;
    this.loadtotalvalide();
    /*this.loadtotalrejete();*/
    this.isLoadingResults = false;
    this.Printparticipant = this.tableData$.results.liste;
    this.pdebut =  values.datedebut;
    this.pfin = values.datefin;
  });
}


loadPayswebinarActif(id){
  this.inscriptionService.getPaticipantPaysValider(id).subscribe(
  paysData => {
    this.allpays = paysData.results.liste;
    console.log(this.allpays);
           }
  );
}

 /*loadtotalrejete() {
 this.inscriptionService.getallPaticipantRejete(2).subscribe(data => {
   this.tableData$ = data;
   this.totalrejetes = this.tableData$.results.total;
 }); 
}
*/


Delete(ID) {
  this.isLoadingResults = true;
  this.inscriptionService.deletePaticipant(ID).subscribe(data => {
    if (data.success === true) {
      this.notificationService.showSuccess(data.message, 'SUCCES');
      this.loadDataTable();
      this.isLoadingResults = false;
    }else {
      this.notificationService.showError(data.message, 'MESSAGE D\'ERREUR');
      this.isLoadingResults = false;
    }
     });
    }

getNavigation(link, id){
 if(id === ''){
     this.router.navigate([link]);
 } else {
     this.router.navigate([link + '/' + id]);
 }
}


getNavigationRetun(link, id, lien){
  if(id === ''){
      this.router.navigate([lien]);
  } else {
      this.router.navigate([link + '/' + id + '/' + lien ]);
  }
}



}
