  <!--==================-->
  <section id="gallery-part">
    <div class="gallery-shape">
        <img src="assets/shape-img1.png" alt="shape-img1">
    </div>
    <div class="gallery-dot">
        <img src="assets/dot-img1.png" alt="dot-img1">
    </div>
    <div class="section-title">
        <p>photos of event</p>
        <h2>Event Gallery</h2>
    </div>
    <div class="active-gallery slick-initialized slick-slider">
        <div class="slick-list draggable"><div class="slick-track" style="opacity: 1; width: 7920px; transform: translate3d(-1584px, 0px, 0px);"><div class="gallery-img slick-slide slick-cloned" data-slick-index="-6" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img1.png" alt="gallery-img1">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery01" href="assets/gallery-img1.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="-5" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img2.png" alt="gallery-img2">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery02" href="assets/gallery-img2.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="-4" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img3.png" alt="gallery-img3">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery03" href="assets/gallery-img3.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="-3" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img4.png" alt="gallery-img4">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery04" href="assets/gallery-img4.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="-2" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img5.png" alt="gallery-img5">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery05" href="assets/gallery-img5.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img6.png" alt="gallery-img6">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery06" href="assets/gallery-img6.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" tabindex="0" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img1.png" alt="gallery-img1">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery01" href="assets/gallery-img1.png" tabindex="0">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-active" data-slick-index="1" aria-hidden="false" tabindex="0" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img2.png" alt="gallery-img2">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery02" href="assets/gallery-img2.png" tabindex="0">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-active" data-slick-index="2" aria-hidden="false" tabindex="0" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img3.png" alt="gallery-img3">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery03" href="assets/gallery-img3.png" tabindex="0">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-active" data-slick-index="3" aria-hidden="false" tabindex="0" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img4.png" alt="gallery-img4">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery04" href="assets/gallery-img4.png" tabindex="0">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-active" data-slick-index="4" aria-hidden="false" tabindex="0" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img5.png" alt="gallery-img5">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery05" href="assets/gallery-img5.png" tabindex="0">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-active" data-slick-index="5" aria-hidden="false" tabindex="0" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img6.png" alt="gallery-img6">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery06" href="assets/gallery-img6.png" tabindex="0">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide" data-slick-index="6" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img1.png" alt="gallery-img1">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery01" href="assets/gallery-img1.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide" data-slick-index="7" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img2.png" alt="gallery-img2">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery02" href="assets/gallery-img2.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide" data-slick-index="8" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img3.png" alt="gallery-img3">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery03" href="assets/gallery-img3.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide" data-slick-index="9" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img4.png" alt="gallery-img4">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery04" href="assets/gallery-img4.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide" data-slick-index="10" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img5.png" alt="gallery-img5">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery05" href="assets/gallery-img5.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide" data-slick-index="11" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img6.png" alt="gallery-img6">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery06" href="assets/gallery-img6.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="12" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img1.png" alt="gallery-img1">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery01" href="assets/gallery-img1.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="13" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img2.png" alt="gallery-img2">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery02" href="assets/gallery-img2.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="14" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img3.png" alt="gallery-img3">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery03" href="assets/gallery-img3.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="15" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img4.png" alt="gallery-img4">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery04" href="assets/gallery-img4.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="16" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img5.png" alt="gallery-img5">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery05" href="assets/gallery-img5.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="17" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img6.png" alt="gallery-img6">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery06" href="assets/gallery-img6.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="18" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img1.png" alt="gallery-img1">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery01" href="assets/gallery-img1.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="19" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img2.png" alt="gallery-img2">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery02" href="assets/gallery-img2.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="20" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img3.png" alt="gallery-img3">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery03" href="assets/gallery-img3.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="21" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img4.png" alt="gallery-img4">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery04" href="assets/gallery-img4.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="22" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img5.png" alt="gallery-img5">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery05" href="assets/gallery-img5.png" tabindex="-1">+</a>
                </div>
            </div>
        </div><div class="gallery-img slick-slide slick-cloned" data-slick-index="23" aria-hidden="true" tabindex="-1" style="width: 264px;">
            <div class="img">
                <img src="assets/gallery-img6.png" alt="gallery-img6">
                <div class="gallery-overlay">
                    <a class="venobox vbox-item" data-gall="gallery06" href="assets/gallery-img6.png" tabindex="-1">+</a>
                </div>
            </div>
        </div></div></div>
        
        
        
        
        
        
        
        
        
        
        
    </div>
    <div class="gallery-btn">
        <a href="http://themesitem.com/demos/html/eventoo/gallery.html">See More</a>
    </div>
</section>
<!--=================
    gallery-part end
==================-->