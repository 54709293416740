import { Component, OnInit } from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { AuthenticationService } from '../service/authentication.service';
import {Router} from '@angular/router';
import { NotificationService } from '../service/notification.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    ValidedForm: FormGroup;
    isLoadingResults = false;
    constructor(
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,) { 
  this.ValidedForm = this.fb.group({
    login: ['', Validators.required],
    motdepasse: ['', Validators.required]
  });
}

ngOnInit() {
this.authenticationService.logout();
}

login(values) {
this.isLoadingResults = true;
const ValidedFormData = new FormData();
ValidedFormData.append('login', values.login);
ValidedFormData.append('motdepasse', values.motdepasse);

this.authenticationService.loginForm(ValidedFormData).subscribe(response => {
this.authenticationService.setUser(response.results);
this.isLoadingResults = false;
},
error => { 
    this.isLoadingResults = false;
    this.notificationService.showError('Utilisateur inconnu', 'Erreur');
  
});
}




}
