 <!--schedule-part start
  ==================-->
  <section id="schedule-part">
  <div class="overlay2">
    <div class="container"> 
        <div class="section-title">
            <p>Horaire</p>
            <h2>Animatrice</h2>
        </div>
        <div class="row">
            <div class="col-lg-10 offset-lg-1 col-md-12">
                <div class="row mx-0">
                    <div class="col-lg-3 col-md-3"></div>
                    <div class="col-lg-8 col-md-8">
                        <div class="speakers-item">
                            <div class="speakers-img">
                                <img src="assets/images/speakers/mme-sarah-adou.png" alt="mme-sarah-adou">
                            </div>
                            <div class="speakers-text">
                                <div class="date">
                                    <p>Groupe 1 &nbsp; 17:00 - 18:00 GMT</p>
                                    <p>Groupe 2 &nbsp; 19:00 - 20:00 GMT</p>
                                </div>
                                <h3>Mme Sarah ADOU</h3>
                                <p>Psychologue Clinicienne.</p>
                                <!--div class="speaker-icon"> 
                                    <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                    <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                     <a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                </div-->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-1"></div>
                
                </div>
            </div>
       
        </div>
    </div>
</div>

</section>
<!--=================
    schedule-part end
==================-->