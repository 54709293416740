<app-dash-top></app-dash-top>



<div id="content" class="section-padding">
    <div class="container">
    <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-3 page-sidebar">
   <app-dash-left-menu></app-dash-left-menu>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-9">
    <div class="row page-content">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <div class="inner-box">
    <div class="dashboard-box">
    <h2 class="dashbord-title" style="font-size: 22px;">{{Title}}</h2>
    </div>
    <div class="dashboard-wrapper">

   

        <div class="dashboard-sections"  *ngIf="dashboard">
            <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
            <div class="dashboardbox">
           
            <div class="contentbox">
            <h2 style="font-size: 20px;"><i class="fa fa-users"></i> Total Inscrits</h2>
            <h3 style="font-size: 25px; color: #ffa913;"> {{totalvalides+totalrejetes}}</h3>
            </div>
            </div>
            </div>
            <!--div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                <div class="dashboardbox">
                
                <div class="contentbox">
                <h2 style="font-size: 20px;"><a href="#"> <i class="fa fa-users"></i> En attente</a></h2>
                <h3 style="font-size: 25px; color: #ffa913;">{{totalInscrits}} </h3>
                </div>
                </div>
                </div-->

            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
            <div class="dashboardbox">
            
            <div class="contentbox">
            <h2 style="font-size: 20px;"><a href="#"> <i class="fa fa-users"></i> Validées</a></h2>
            <h3 style="font-size: 25px; color: #59B200;">{{totalvalides}} </h3>
            </div>
            </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
            <div class="dashboardbox">
          
            <div class="contentbox">
            <h2 style="font-size: 20px;"><a href="#"><i class="fa fa-users"></i> Rejetées</a></h2>
            <h3 style="font-size: 25px; color: red;">{{totalrejetes}}</h3>
            </div>
            </div>
            </div>
            </div>
            </div>

            <div class="spinner4" *ngIf="isLoadingResults">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>

            <h2 class="dashbord-title" style="font-size: 22px; padding: 10px;">Inscription en attente de validation <button class="btn btn-danger" style="float: right;" [useExistingCss]="true" printTitle="{{imprimTitle}}"  printSectionId="pdfTable" ngxPrint>IMPRIMER <i class="fa fa-print" aria-hidden="true"></i></button></h2>
            <!--mat-form-field style="padding-left: 5px;">
              <mat-label style="text-align: right;"></mat-label>
                <input matInput (keyup)="applyFilter($event)" type="text" placeholder="Effectuer une recherche">
              </mat-form-field-->

              <form [formGroup]="validedForm" (ngSubmit)="search(validedForm.value)" style=" padding-top: 15px;"> <!--(change)="onChangeSelectedValue($event)"-->
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <select  formControlName="IDEvent"  class="form-control is-invalid" style="width: 100%;">
                          <option selected="selected" value="">---- Choisir un thème ----</option> 
                           <option *ngFor="let pays of allwebinar;"  [value]="pays.IDEvent" id="{{pays.IDEvent}}">
                              {{pays.webi_theme | uppercase}}
                          </option></select> 
                       
                         </div>
                <div class="form-group col-md-3">
                  <select  formControlName="ptc_Pays"  class="form-control" style="width: 100%;">
                    <option selected="selected" value="">---- PAYS ----</option> 
                     <option *ngFor="let pays of allpays;"  [value]="pays.ptc_Pays" id="{{pays.ptc_Pays}}" [attr.name-pays]="pays.ptc_Pays">
                        {{pays.ptc_Pays | uppercase}}
                    </option></select> 
                 
                   </div>
                <div class="form-group col-md-2">
                  <select  formControlName="sexe" class="form-control" style="width: 100%;">
                    <option selected="selected" value="" [attr.name-sexe]="0">-- SEXE--</option> 
                    <option value="F" [attr.name-sexe]="'F'">
                        {{'feminin' | uppercase}}
                    </option>
                    <option value="M" [attr.name-sexe]= "'M'">
                      {{'Masculin' | uppercase}}
                  </option>
                </select> 
                </div>

                <div class="form-group col-md-3">
                  <input type="date" formControlName="datedebut" class="form-control is-invalid" placeholder="Période Début"/>
                </div>
                <div class="form-group col-md-3">
                  <input type="date" formControlName="datefin" class="form-control is-invalid" placeholder="Période Fin"/>
                </div>
                <div class="form-group col-md-1" style="padding-bottom: 10px;">
                  <button type="submit" [disabled]="!validedForm.valid" class="btn btn-primary">VALIDER</button>
                    
                </div>
              </div>
              </form>
              
          
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
    
                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="position">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="nomprenom">
                  <th mat-header-cell *matHeaderCellDef> Nom et Prénom </th>
                  <td mat-cell *matCellDef="let element"> {{element.ptc_Nom +' '+element.ptc_Prenoms | uppercase}} </td>
                </ng-container>
              
                <!-- Weight Column -->
                <ng-container matColumnDef="pays">
                  <th mat-header-cell *matHeaderCellDef> Pays </th>
                  <td mat-cell *matCellDef="let element"> {{element.ptc_Pays  | uppercase }} </td>
                </ng-container>
              
                <!-- Symbol Column --> 
                <ng-container matColumnDef="sexe">
                  <th mat-header-cell *matHeaderCellDef> Sexe </th>
                  <td mat-cell *matCellDef="let element"> {{element.ptc_sexe}} </td>
                </ng-container>

                <ng-container matColumnDef="contact">
                    <th mat-header-cell *matHeaderCellDef> Inscrit le </th>
                    <td mat-cell *matCellDef="let element"> {{element.ptc_date | date: 'dd/MM/yyyy'}} </td>
                  </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef> Action </th> 
                    <td mat-cell *matCellDef="let element" data-title="Action">   
                      <div class="btns-actions">
                        <button type="button" class="btn btn-icon btn-info mr-1" (click)="getNavigationRetun('/webinair/participant/', element.IDParticipation,lienRetour)" title="Editer" formtarget="_blank"><i class="fa fa-edit"></i></button>
                       <!--button type="button" class="btn btn-icon btn-primary btn-danger" mwlConfirmationPopover [popoverTitle]="popoverTitle" [popoverMessage]="popoverMessage" placement="left" (cancel)="cancelClicked = true"><i class="fa fa-trash"></i></button-->
                                             
                    </div>
                    </td>
                  </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
    


    </div>
    </div>
    </div>
  
    </div>
    </div>
  
  
  


    <div class="row" hidden>
      <div id="pdfTable" pdfTable>

        <table width="100%" >
          <tr>
               <td colspan="2">
                <div class="invoice-left" style="padding: 25px; text-align: center;">
                  <img class="logo" src="../../../assets/logo-procrea.jpg" alt="logo">
                  </div>
               </td>
             </tr>
             <tr>
                 <td colspan="2"><div class="tt-bord">
                   Rencontres Web Hebdomadaires <br>
                     THEME : {{webitheme}}</div>
                 </td>
             </tr>
          
       
      
             <tr>
              <td><div class="row tt-sous-glob" style="font-weight: bold; padding-top: 10px; padding-bottom: 5px;">
                <span *ngIf="pfin!='0'">
                  PERIODE: {{pdebut | date: 'dd/MM/yyyy'}} AU {{pfin | date: 'dd/MM/yyyy'}}<br>
                 </span>
                 <span *ngIf="pfin =='0'">
                  PERIODE: {{date | date: 'dd/MM/yyyy'}} <br>
                 </span>
      </div></td>
              <td></td>
          </tr>

         
             <tr>
               <td colspan="2"><table width="100%" class="cMonTableau4">
                 <tr>
                   <th class="list-tt01" colspan="12" ><div>{{imprimTitle}}</div></th>
                 </tr>
                 <tr>
                   <th class="list-head-tt">#</th>
                   <th class="list-head-tt">Civilité</th>
                   <th class="list-head-tt">Nom et Prénoms</th>
                   <th class="list-head-tt">Situation matrimoniale</th>
                   <th class="list-head-tt">Nom de jeune fille</th>
                   <th class="list-head-tt">Sexe</th>
                   <th class="list-head-tt">Pays</th>
                   <th class="list-head-tt">Ville</th>
                   <th class="list-head-tt">Date de naissance</th>
                   <th class="list-head-tt">Email</th>
                   <th class="list-head-tt">Téléphone</th>
                   <th class="list-head-tt">Inscrit le.</th>
                 </tr>
                 <tr *ngFor="let pat of Printparticipant; let i = index"><!---->
                   <th class="list-head">{{i + 1}}</th>
                   <th class="list-head">{{pat.ptc_civilite | uppercase}}</th>
                   <th class="list-head" style="text-align: left;">{{pat.ptc_Nom + ' '+pat.ptc_Prenoms | uppercase}}</th>
                   <th class="list-head">{{pat.ptc_smatrimoniale | uppercase}}</th>
                   <th  class="list-head" >{{pat.ptc_NomJeunefille | uppercase}}</th>
                   <th  class="list-head" >{{pat.ptc_sexe | uppercase}}</th>
                   <th  class="list-head" >{{pat.ptc_Pays | uppercase}}</th>
                   <th  class="list-head" >{{pat.ptc_Ville | uppercase}}</th>
                   <th  class="list-head" >{{pat.ptc_Datenaissance | date: 'dd/MM/yyyy'}}</th>
                   <th  class="list-head" style="text-align: left;">{{pat.ptc_Email}}</th>
                   <th  class="list-head" >{{pat.ptc_Mobile | uppercase}}</th>
                   <th  class="list-head" >{{pat.ptc_date | date: 'dd/MM/yyyy'}}</th>
                 </tr>
               </table></td>
             </tr>
             <tr>
              <td colspan="2">&nbsp;</td>
            </tr>
          
             <tr>
               <td colspan="2" style="text-align: right; margin-top: 20px;">
                  <div class="pull-right list-head-footer">
            Imprimé le {{date | date: 'dd/MM/yyyy'}} par {{Usernom + ' ' +Userprenoms}}
          </div>
               </td>
             </tr>
           </table>

      </div>
  </div>


    </div>
  </div>
  </div>