import { Injectable } from '@angular/core';
import {ConfigurationService} from './configuration.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class InscriptionService {

  url = this.configurationService.urlWebapi;

  constructor(private httpClient: HttpClient,
              private configurationService: ConfigurationService) { }

/////// APPEL HTTP DE BASE ///////////

createPaticipant(data): Observable<any> {
  return this.httpClient.post<any>(this.url + 'webinar/ajout.php', data);
}

getTouslesPaticipants(): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/listeparticipant.php');
}

getallPaticipant(): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/listenontraite.php?ptc_etat=0');
}

getallPaticipantValider(id): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/listenontraite.php?ptc_etat=' + id);
}

getallPaticipantRejete(id): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/listenontraite.php?ptc_etat=' + id);
}

getPaticipantBy(id): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/liste.php?IDParticipation=' + id);
}


updatePaticipant(etat): Observable<any> {
  return this.httpClient.post<any>(this.url + 'webinar/modifier.php', etat);
}

deletePaticipant(id): Observable<any> {
  return this.httpClient.delete<any>(this.url + 'webinar/supprimer.php?IDParticipation=' + id);
}

getPaticipantPays(id): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/liste-participant-pays.php?p=' + id);
}

getallPaticipantByPays(pays): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/search-listenontraite.php?pays=' + pays);
}


getPaticipantPaysValider(id): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/liste-participant-pays-valider.php?p=' + id);
}

getPaticipantPaysRejeter(id): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/liste-participant-pays-rejeter.php?p=' + id);
}


searchPaticipant(search): Observable<any> {
  return this.httpClient.post<any>(this.url + 'webinar/search.php', search);
}

searchPaticipantValide(search): Observable<any> {
  return this.httpClient.post<any>(this.url + 'webinar/search-valider.php', search);
}

searchPaticipantRejeter(search): Observable<any> {
  return this.httpClient.post<any>(this.url + 'webinar/search-rejeter.php', search);
}



/***HISTORIQUE*********** */

getallwebina(): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/histo-webinar.php?id=0');
}

getPaticipantPays_histo(id): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/histo-liste-participant-pays.php?p=' + id);
}

searchPaticipant_histo(search): Observable<any> {
  return this.httpClient.post<any>(this.url + 'webinar/histo-search.php', search);
}

getallPaticipantValider_histo(id, IDEvent, datedebut, datefin): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/histo-listenontraite.php?ptc_etat=' + id + '&IDEvent=' + IDEvent + '&debut=' + datedebut+ '&defin=' + datefin);
}

getallPaticipantRejete_histo(id, IDEvent, datedebut, datefin): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/histo-listenontraite.php?ptc_etat=' + id + '&IDEvent=' + IDEvent + '&debut=' + datedebut+ '&defin=' + datefin);
}

/*getallPaticipantByPays(pays, sexe): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/search-listenontraite.php?pays=' + pays + '&sexe=' + sexe);
}*/

/*creerArticle(article): Observable<any> {
  return this.httpClient.post<any>(this.url + 'api/create/article.php', article);
}*/



}
