import { Injectable } from '@angular/core';
import {ConfigurationService} from './configuration.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class UserService {

 
  url = this.configurationService.urlWebapi;

  constructor(private httpClient: HttpClient,
              private configurationService: ConfigurationService) { }

/////// APPEL HTTP DE BASE ///////////

createUser(data): Observable<any> {
  return this.httpClient.post<any>(this.url + 'webinar/creationutilisateur.php', data);
}



getallUser(): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/liste-utilisateur.php?utilId=0');
}

deleteUser(id): Observable<any> {
  return this.httpClient.delete<any>(this.url + 'webinar/supprimer-utilisateur.php?utilId=' + id);
}


getUserbyId(id): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/liste-utilisateur.php?utilId=' + id);
}

updatetUser(data): Observable<any> {
  return this.httpClient.post<any>(this.url + 'webinar/modifier-utilisateur.php', data);
}

resetPassword(data): Observable<any> {
  return this.httpClient.post<any>(this.url + 'webinar/premierecnx.php', data);
}



}
