import { Injectable } from '@angular/core';
import {ConfigurationService} from './configuration.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class WebinarService {

 
  url = this.configurationService.urlWebapi;

  constructor(private httpClient: HttpClient,
              private configurationService: ConfigurationService) { }

/////// APPEL HTTP DE BASE ///////////

createWebinar(data): Observable<any> {
  return this.httpClient.post<any>(this.url + 'webinar/ajout-webinar.php', data);
}



getallWebinar(): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/liste-webinar.php?IDEvent=0');
}

deleteWebinar(id): Observable<any> {
  return this.httpClient.delete<any>(this.url + 'webinar/supprimer-webinar.php?IDEvent=' + id);
}


getWebinarbyId(id): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/liste-webinar.php?IDEvent=' + id);
}

updatetWebinar(data): Observable<any> {
  return this.httpClient.post<any>(this.url + 'webinar/modifier-webinar.php', data);
}


getWebinaractif(): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/liste-webinar-actif.php');
}


/*getallPaticipantRejete(id): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/listenontraite.php?ptc_etat=' + id);
}

getPaticipantBy(id): Observable<any> {
  return this.httpClient.get<any>(this.url + 'webinar/liste.php?IDParticipation=' + id);
}




*/



}
