      <!--=================
        testimonial-part start
    ==================-->
    <section id="testimonial-part">
        <div class="test-shape">
            <img src="assets/shape-img2.png" alt="shape-img2">
        </div>
        <div class="test-dot">
            <img src="assets/dot-img1.png" alt="dot-img1">
        </div>
        <div class="container">
            <div class="section-title">
                <p>positive reviews</p>
                <h2>Events Feedback</h2>
            </div>
            <div class="row testimonial-active slick-initialized slick-slider"><i class="fa fa-angle-left prev slick-arrow" style="display: block;"></i>
                <div class="slick-list draggable"><div class="slick-track" style="opacity: 1; width: 5700px; transform: translate3d(-1140px, 0px, 0px);"><div class="col-lg-6 slick-slide slick-cloned" data-slick-index="-2" aria-hidden="true" tabindex="-1" style="width: 570px;">
                    <div class="single-testimonial">
                        <p class="testi-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        <div class="testi-img">
                            <img src="assets/testimonial-img1.png" alt="testimonial-img1">
                            <div class="icon">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                        <h3>Jonathon Reverse</h3>
                        <p class="designation">Event Sponsor</p>
                    </div>
                </div><div class="col-lg-6 slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1" style="width: 570px;">
                    <div class="single-testimonial">
                        <p class="testi-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        <div class="testi-img">
                            <img src="assets/testimonial-img2.png" alt="testimonial-img2">
                            <div class="icon">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                        <h3>Jerinno Lopez</h3>
                        <p class="designation">Event Sponsor</p>
                    </div>
                </div><div class="col-lg-6 slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" tabindex="0" style="width: 570px;">
                    <div class="single-testimonial">
                        <p class="testi-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        <div class="testi-img">
                            <img src="assets/testimonial-img1.png" alt="testimonial-img1">
                            <div class="icon">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                        <h3>Jonathon Reverse</h3>
                        <p class="designation">Event Sponsor</p>
                    </div>
                </div><div class="col-lg-6 slick-slide slick-active" data-slick-index="1" aria-hidden="false" tabindex="0" style="width: 570px;">
                    <div class="single-testimonial">
                        <p class="testi-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        <div class="testi-img">
                            <img src="assets/testimonial-img2.png" alt="testimonial-img2">
                            <div class="icon">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                        <h3>Jerinno Lopez</h3>
                        <p class="designation">Event Sponsor</p>
                    </div>
                </div><div class="col-lg-6 slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1" style="width: 570px;">
                    <div class="single-testimonial">
                        <p class="testi-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        <div class="testi-img">
                            <img src="assets/testimonial-img1.png" alt="testimonial-img1">
                            <div class="icon">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                        <h3>Jonathon Reverse</h3>
                        <p class="designation">Event Sponsor</p>
                    </div>
                </div><div class="col-lg-6 slick-slide" data-slick-index="3" aria-hidden="true" tabindex="-1" style="width: 570px;">
                    <div class="single-testimonial">
                        <p class="testi-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        <div class="testi-img">
                            <img src="assets/testimonial-img2.png" alt="testimonial-img2">
                            <div class="icon">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                        <h3>Jerinno Lopez</h3>
                        <p class="designation">Event Sponsor</p>
                    </div>
                </div><div class="col-lg-6 slick-slide slick-cloned" data-slick-index="4" aria-hidden="true" tabindex="-1" style="width: 570px;">
                    <div class="single-testimonial">
                        <p class="testi-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        <div class="testi-img">
                            <img src="assets/testimonial-img1.png" alt="testimonial-img1">
                            <div class="icon">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                        <h3>Jonathon Reverse</h3>
                        <p class="designation">Event Sponsor</p>
                    </div>
                </div><div class="col-lg-6 slick-slide slick-cloned" data-slick-index="5" aria-hidden="true" tabindex="-1" style="width: 570px;">
                    <div class="single-testimonial">
                        <p class="testi-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        <div class="testi-img">
                            <img src="assets/testimonial-img2.png" alt="testimonial-img2">
                            <div class="icon">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                        <h3>Jerinno Lopez</h3>
                        <p class="designation">Event Sponsor</p>
                    </div>
                </div><div class="col-lg-6 slick-slide slick-cloned" data-slick-index="6" aria-hidden="true" tabindex="-1" style="width: 570px;">
                    <div class="single-testimonial">
                        <p class="testi-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        <div class="testi-img">
                            <img src="assets/testimonial-img1.png" alt="testimonial-img1">
                            <div class="icon">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                        <h3>Jonathon Reverse</h3>
                        <p class="designation">Event Sponsor</p>
                    </div>
                </div><div class="col-lg-6 slick-slide slick-cloned" data-slick-index="7" aria-hidden="true" tabindex="-1" style="width: 570px;">
                    <div class="single-testimonial">
                        <p class="testi-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed dosmod tempor incididunt ut labore etdolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        <div class="testi-img">
                            <img src="assets/testimonial-img2.png" alt="testimonial-img2">
                            <div class="icon">
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </div>
                        </div>
                        <h3>Jerinno Lopez</h3>
                        <p class="designation">Event Sponsor</p>
                    </div>
                </div></div></div>
                
                
                
            <i class="fa fa-angle-right next slick-arrow" style="display: block;"></i></div>
        </div>
    </section>
    <!--=================
        testimonial-part end
    ==================-->