import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { NotificationService } from '../../service/notification.service';
import { InscriptionService } from '../../service/inscription.service';
import { WebinarService } from '../../service/webinar.service';


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  public IDParticipation: any = Math.floor(Math.random() * ( 171807 - 342978 + 1) + 171807);
  public date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  isLoadingResults = true;
  IDParticipationGener: any;
  IDEvent: any = 1;
  validedForm: FormGroup;
  statusbtn: boolean = false;
  submitted = false;
  tableData$: any;
  theme: any;
  slogan: any;
  lientemoignage: any;
  webidesciption: any;
  webicontact1: any;
  webicontact2: any;
  webiimage: any;
  webidate1: any;
  webidate2: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private inscriptionService: InscriptionService,
    private datePipe: DatePipe,
    private webinarService: WebinarService,
    private notifyService: NotificationService) {
    this.validedForm = this.fb.group({
      IDParticipation: [this.IDParticipation],
      IDEvent: [this.IDParticipation],
      ptc_civilite: ['', Validators.required],
      ptc_sexe: ['', Validators.required],
      ptc_smatrimoniale: ['', Validators.required],
      ptc_Nom: ['', Validators.required],
      ptc_Prenoms: ['', Validators.required],
      ptc_NomJeunefille: [''],
      ptc_Pays: ['Cote_d_Ivoire', Validators.required],
      ptc_Ville: ['', Validators.required],
      ptc_Datenaissance: [''],
      ptc_Email: ['', Validators.required],
      ptc_Mobile: ['', Validators.required],
      ptc_recevMessaeg: [''],
      ptc_date: [''],
      /*Util_id: [this.UserID], , Validators.required*/
    });

   }

  ngOnInit(): void {
  this.loadDataTable();

  }


  saveForm(values){
    this.statusbtn = true;
    const ValidedFormData = new FormData();
    ValidedFormData.append('IDParticipation', values.IDParticipation);
    ValidedFormData.append('IDEvent', values.IDEvent);
    ValidedFormData.append('ptc_civilite', values.ptc_civilite);
    ValidedFormData.append('ptc_sexe', values.ptc_sexe);
    ValidedFormData.append('ptc_smatrimoniale', values.ptc_smatrimoniale);
    ValidedFormData.append('ptc_Nom', values.ptc_Nom);
    ValidedFormData.append('ptc_Prenoms', values.ptc_Prenoms);
    ValidedFormData.append('ptc_NomJeunefille', values.ptc_NomJeunefille);
    ValidedFormData.append('ptc_Pays', values.ptc_Pays);
    ValidedFormData.append('ptc_Ville', values.ptc_Ville);
    ValidedFormData.append('ptc_Datenaissance', values.ptc_Datenaissance);
    ValidedFormData.append('ptc_Email', values.ptc_Email);
    ValidedFormData.append('ptc_Mobile', values.ptc_Mobile);
    ValidedFormData.append('ptc_recevMessaeg', values.ptc_recevMessaeg);
    ValidedFormData.append('ptc_date', this.date);
    ValidedFormData.append('ptc_etat', '0');

    
     /* ValidedFormData.append('Util_id', this.UserID);*/

   /*nscription d'un participant*/
    this.inscriptionService.createPaticipant(ValidedFormData).subscribe(result => {
      if (result.success === true) {
        this.notifyService.showSuccess(result.message, 'SUCCES');
        this.statusbtn = false;
        this.generateArticleID();
        
      } else {
        this.notifyService.showError(result.message, 'MESSAGE D\'ERREUR');
        this.statusbtn = false;
      }  /**/
     
      /*if (result=='2'){
        this.isLoadingResults = false;
        this.toastr.error('Le Entrepôt d\'article saisie semble existé déjà !!!', 'ERREUR');
      }

      this.isLoadingResults = false;*/

    });
  }


  generateArticleID(){
    this.validedForm.reset();
    this.IDParticipationGener = Math.floor(Math.random() * ( 171807 - 342978 + 1) + 171807);
    this.validedForm.controls['IDParticipation'].setValue(this.IDParticipationGener);
    this.validedForm.controls['ptc_civilite'].setValue('');
    this.validedForm.controls['ptc_sexe'].setValue('');
    this.validedForm.controls['ptc_smatrimoniale'].setValue('');
    this.validedForm.controls['ptc_Pays'].setValue('Cote_d_Ivoire');
    this.validedForm.controls['ptc_recevMessaeg'].setValue('1');
    this.validedForm.controls['IDEvent'].setValue(this.tableData$.results.liste[0].IDEvent);
  }

  getNavigation(link, id){
    if(id === ''){
        this.router.navigate([link]);
    } else {
        this.router.navigate([link + '/' + id]);
    }
}



/*//////////////////////*/
loadDataTable() {
  /*this.isLoadingResults = true;*/ 
  this.webinarService.getWebinaractif().subscribe(data => {
    this.tableData$ = data;
    this.theme =  this.tableData$.results.liste[0].webi_theme;
    this.slogan =  this.tableData$.results.liste[0].webi_slogan;
    this.lientemoignage =  this.tableData$.results.liste[0].webi_youtube;
    this.validedForm.controls['IDEvent'].setValue(this.tableData$.results.liste[0].IDEvent);
    this.webidesciption =  this.tableData$.results.liste[0].webi_desciption;
    this.webicontact1 =  this.tableData$.results.liste[0].webi_contact1;
    this.webicontact2 =  this.tableData$.results.liste[0].webi_contact2;
    this.webiimage =  this.webinarService.url + this.tableData$.results.liste[0].webi_image;
    this.webidate1 =  this.tableData$.results.liste[0].webi_date1;
    this.webidate2 =  this.tableData$.results.liste[0].webi_date2;
    this.isLoadingResults = false;
   /* this.totalWebinar = this.tableData$.results.total;
    this.dataSource.data = this.tableData$.results.liste.reverse();*/
    /*this.isLoadingResults = false;*/
    /*this.loadtotalvalide();
    this.loadtotalrejete();*/
   
  });  /**/
 }

}
