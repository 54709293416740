<app-dash-top></app-dash-top>


<section id="brand-part" style="padding: 30px;">
   
    <div class="col-md-6 offset-md-3 mt-0">
        <!--div class="alert alert-info">
            Username: test<br />
            Password: test
        </div-->
        <div class="card">
            <h4 class="card-header">Modification de votre mot de passe</h4>
            <div class="card-body">
                <div class="spinner4" *ngIf="isLoadingResults"> 
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
                <form [formGroup]="ValidedForm" (ngSubmit)="login(ValidedForm.value)">
             

                    <div class="form-group">
                        <input type="password" formControlName="oldmotdepasse" class="form-control"/>
                        <label class="control-label" for="input-login">Mot de passe actuel</label><i class="bar"></i>
                        <div class="alert-danger" *ngIf="!ValidedForm.controls['oldmotdepasse'].valid && (ValidedForm.controls['oldmotdepasse'].touched)">
                            <div [hidden]="!ValidedForm.controls['oldmotdepasse'].errors.required">
                                    Le Mot de passe actuel est obligatoire
                                  </div>  
                    </div>
                    </div>
                    
                    
                    
                    
                    <div class="form-group">
                        <input type="password" formControlName="newmotdepasse" class="form-control"/>
                        <label class="control-label" for="input-login">Nouveau mot de passe</label><i class="bar"></i>
                        <div class="alert-danger" *ngIf="!ValidedForm.controls['newmotdepasse'].valid && (ValidedForm.controls['newmotdepasse'].touched)">
                            <div [hidden]="!ValidedForm.controls['newmotdepasse'].errors.required">
                                    Le Nouveau mot de passe est obligatoire
                                  </div>  
                    </div>
                    </div>

                    <div class="form-group">
                        <input formControlName="renewmotdepasse" class="form-control"  type="password" id="input-password">
                        <label class="control-label" for="input-password">Confirmation du mot de passe</label><i class="bar"></i>
                        <div class="alert-danger" *ngIf="!ValidedForm.controls['renewmotdepasse'].valid && (ValidedForm.controls['renewmotdepasse'].touched)">
                            <div [hidden]="!ValidedForm.controls['renewmotdepasse'].errors.required">
                                    La Confirmation du mot de passe est obligatoire
                                  </div>  
                    </div>
                    </div>

                  
                    <button [disabled]="!ValidedForm.valid" type="submit" class="btn btn-login float-button-light">Connexion</button>
                   
                </form>
            </div>
        </div>
    </div>
    
 </section>
