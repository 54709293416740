<app-dash-top></app-dash-top>



<div id="content" class="section-padding">
    <div class="container">
    <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-3 page-sidebar">
   <app-dash-left-menu></app-dash-left-menu>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-9">
    <div class="row page-content">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    
        <div class="inner-box">
            <div class="dashboard-box">
            <h2 class="dashbord-title">{{Title}} 
                <span class="badge badge-success" *ngIf="ptcetat=='1'">VALIER</span>
                <span class="badge badge-warning" *ngIf="ptcetat=='2'">REJETER</span></h2>
            </div>
            <div class="spinner4" *ngIf="isLoadingResults">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <div class="dashboard-wrapper">

                <form [formGroup]="validedForm" (ngSubmit)="saveForm(validedForm.value)">
                    <input type="text" hidden class="form-group" formControlName="IDParticipation" placeholder="IDParticipation">
                    <div class="form-row">
                        <div class="form-group col-md-4">
                          <label for="inputEmail4">Civilité</label>
                          <input name="ptc_civilite" type="text" formControlName="ptc_civilite" class="form-control" id="inputEmail4">
                        </div>
                        <div class="form-group col-md-4">
                          <label for="inputPassword4">Sexe</label>
                          <input name="ptc_sexe" formControlName="ptc_sexe" type="text" class="form-control" id="inputPassword4">
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputPassword4">Situation matrimoniale</label>
                            <input name="ptc_smatrimoniale" formControlName="ptc_smatrimoniale"  type="text" class="form-control" id="inputPassword4">
                          </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-md-4">
                          <label for="inputEmail4">Nom</label>
                          <input name="ptc_Nom" type="text" formControlName="ptc_Nom" class="form-control" id="inputEmail4">
                        </div>
                        <div class="form-group col-md-4">
                          <label for="inputPassword4">Prénom</label>
                          <input name="ptc_Prenoms" formControlName="ptc_Prenoms" type="text" class="form-control" id="inputPassword4">
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputPassword4">Nom de jeune fille</label>
                            <input name="ptc_NomJeunefille" formControlName="ptc_NomJeunefille"  type="text" class="form-control" id="inputPassword4">
                          </div>
                      </div> 

                      <div class="form-row">
                        <div class="form-group col-md-4">
                          <label for="inputEmail4">Pays</label>
                          <input name="ptc_Pays" type="text" formControlName="ptc_Pays" class="form-control" id="inputEmail4">
                        </div>
                        <div class="form-group col-md-4">
                          <label for="inputPassword4">Ville</label>
                          <input name="ptc_Ville" formControlName="ptc_Ville" type="text" class="form-control" id="inputPassword4">
                        </div>
                        <div class="form-group col-md-4">
                            <label for="inputPassword4">Date de naissance</label>
                            <input name="ptc_Datenaissance" formControlName="ptc_Datenaissance"  type="text" class="form-control" id="inputPassword4">
                          </div>
                      </div> 

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="inputEmail4">Email</label>
                        <input type="email" name="ptc_Email" formControlName="ptc_Email"  class="form-control" id="inputEmail4">
                      </div>
                      <div class="form-group col-md-6">
                        <label for="inputPassword4">Téléphone</label>
                        <input type="text" name="ptc_Mobile" formControlName="ptc_Mobile" class="form-control" id="inputPassword4">
                      </div>
                    </div>
              
                    <div class="form-group">
                        <div class="tg-checkbox mt-3">
                            <div class="custom-control custom-checkbox">
                            <input type="checkbox"  name="ptc_recevMessaeg" [ngModel]="1" formControlName="ptc_recevMessaeg" class="custom-control-input" id="tg-priceoncall">
                            <label class="custom-control-label" for="tg-priceoncall">Je consens à recevoir des messages électroniques (informations, invitations, offres) de la part de la Clinique PROCREA.
                            </label>
                            </div>
                            </div>
 
                    </div>
                   
                    <div style="text-align: center;">
                        <button class="btn mr-2 btn-warning" [hidden]="ptcetat=='2'" (click)="getvalidate('2')"><i class="fa fa-trash"></i> REJETER</button>
                        <button class="btn mr-2 btn-success" [hidden]="ptcetat=='1'" (click)="getvalidate('1')"><i class="fa fa-check"></i> VALIDER</button>
                        <button class="btn mr-2 btn-danger" (click)="getNavigation(lienRetour, '')"><i class="fa fa-close"></i> ANNULER</button>
                    </div>
                  </form>
                  

           
            </div>
            </div>


    </div>
  
    </div>
    </div>
    </div>
    </div>
    </div>


