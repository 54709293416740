
      <!--=================
        footer-part start
    ==================--> 
    <section id="footer-part">
        <div class="container">
      
            <div class="row">
                <div class="col-lg-6 offset-lg-3">
                    <div class="footer-widget">
                   
                        <div class="footer-icon">
                            <a href="https://web.facebook.com/CliniqueProcrea" target="_blank">
                                <i class="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                            <a href="https://twitter.com/CliniqueProcrea" target="_blank">
                                <i class="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                        
                            <a href="https://www.youtube.com/channel/UCV5Vn0Is0cTV3oHfvVJM1Ng/featured" target="_blank">
                                <i class="fa fa-youtube" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div class="footer-menu">
                            <ul>    
                                <li><a href="">JE M'INSCRIRE</a></li>
                                 </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright">
                <p>© Copyright 2020. All rights reserved by Innovation-ci.</p>
            </div>
    </section>
    <!--=================
        footer-part end
    ==================-->

    <!--ol>
        <li>
          <mat-form-field>
            <mat-label>What's your name?</mat-label>
            <input matInput [(ngModel)]="name">
          </mat-form-field>
        </li>
        <li>
          <button mat-raised-button (click)="openDialog()">Pick one</button>
        </li>
        <li *ngIf="animal">
          You chose: <i>{{animal}}</i>
        </li>
      </ol-->