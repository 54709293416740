import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebinarService } from '../../service/webinar.service';

@Component({
  selector: 'app-dash-top',
  templateUrl: './dash-top.component.html',
  styleUrls: ['./dash-top.component.css']
})
export class DashTopComponent implements OnInit {


  isLoadingResults = true;
  IDParticipationGener: any;
  IDEvent: any = 1;
  statusbtn: boolean = false;
  submitted = false;
  tableData$: any;

  webidesciption: any;
  webicontact1: any;
  webicontact2: any;
  theme: any;
  webiemail1: any;
  webiemail2: any;
  constructor(
    private router: Router,
    private webinarService: WebinarService) {}

  ngOnInit(): void {
  this.loadDataTable();

  }


  getNavigation(link, id){
    if(id === ''){
        this.router.navigate([link]);
    } else {
        this.router.navigate([link + '/' + id]);
    }
}



/*//////////////////////*/
loadDataTable() {
  this.webinarService.getWebinaractif().subscribe(data => {
    this.tableData$ = data;
    this.theme =  this.tableData$.results.liste[0].webi_theme;
    this.webidesciption =  this.tableData$.results.liste[0].webi_desciption;
    this.webicontact1 =  this.tableData$.results.liste[0].webi_contact1;
    this.webicontact2 =  this.tableData$.results.liste[0].webi_contact2;
    this.webiemail1 =  this.tableData$.results.liste[0].webi_email1;
    this.webiemail2 =  this.tableData$.results.liste[0].webi_email2;
    this.isLoadingResults = false;
  });  /**/
 }


}
