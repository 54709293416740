<app-dash-top></app-dash-top>



<div id="content" class="section-padding">
    <div class="container">
    <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-3 page-sidebar">
   <app-dash-left-menu></app-dash-left-menu>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-9">
    <div class="row page-content">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <div class="inner-box">
    <div class="dashboard-box">
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
    <h2 class="dashbord-title" style="font-size: 22px;">{{Title}}</h2>
    </div>
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-12" style="text-align: right;">
        <button class="btn mr-2 btn-info" [routerLink]="['/webinaire/create']" routerLinkActive="router-link-active" ><i class="fa fa-podcast"></i> créer un webinar</button>
                        
        </div>
    </div>
    
    <div class="dashboard-wrapper">

   

        <div class="dashboard-sections">
            <div class="row">
            <!--div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
            <div class="dashboardbox">
           
            <div class="contentbox">
            <h2 style="font-size: 20px;"><i class="fa fa-users"></i> Total Inscrits</h2>
            <h3 style="font-size: 25px; color: #ffa913;"> {{totalInscrits+totalvalides+totalrejetes}}</h3>
            </div>
            </div>
            </div-->
            <!--div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                <div class="dashboardbox">
                
                <div class="contentbox">
                <h2 style="font-size: 20px;"><a href="#"> <i class="fa fa-users"></i> En attente</a></h2>
                <h3 style="font-size: 25px; color: #ffa913;">{{totalInscrits}} </h3>
                </div>
                </div>
                </div-->

            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
            <div class="dashboardbox">
            
            <div class="contentbox">
            <h2 style="font-size: 20px;"><a href="#"> <i class="fa fa-users"></i> Total Webinar</a></h2>
            <h3 style="font-size: 25px; color: red;">{{totalWebinar}} </h3>
            </div>
            </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
            <div class="dashboardbox">
          
            <!--div class="contentbox">
            <h2 style="font-size: 20px;"><a href="#"><i class="fa fa-users"></i> Rejetés</a></h2>
            <h3 style="font-size: 25px; color: red;">{{totalrejetes}}</h3>
            </div-->
            </div>
            </div>
            </div>
            </div>

            <div class="spinner4" *ngIf="isLoadingResults">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>

            <!--h2 class="dashbord-title" style="font-size: 22px; padding: 10px;">Inscriptions rejetées</h2-->
            <mat-form-field style="padding-left: 5px;">
                <mat-label style="text-align: right;">Effectuer une recherche</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Effectuer une recherche">
              </mat-form-field>
    
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
    
                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="position">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="theme">
                  <th mat-header-cell *matHeaderCellDef> Thème </th>
                  <td mat-cell *matCellDef="let element"> {{element.webi_theme | uppercase}} </td>
                </ng-container>
              
                    <!-- Name Column -->
                    <ng-container matColumnDef="datacreate">
                        <th mat-header-cell *matHeaderCellDef> créé le </th>
                        <td mat-cell *matCellDef="let element"> {{element.webi_datecreate | date: 'dd/MM/yyyy'}} </td>
                      </ng-container>
               
                <ng-container matColumnDef="etat">
                    <th mat-header-cell *matHeaderCellDef> Statut </th>
                    <td mat-cell *matCellDef="let element"> 
                        <span class="badge badge-success" style="font-size: 11px;" *ngIf="element.webi_etat ==='1'">ACTIVE</span>
                        <span href="#" class="badge badge-danger" style="font-size: 11px;"*ngIf="element.webi_etat ==='0'">DESACTIVE</span>
                       </td>
                  </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef> Action </th> 
                    <td mat-cell *matCellDef="let element" data-title="Action">   
                      <div class="btns-actions">
                        <button type="button" class="btn btn-icon btn-info mr-1" (click)="getNavigation('/webinaire/update/', element.IDEvent)" title="Editer"><i class="fa fa-edit"></i></button>
                       <button type="button" class="btn btn-icon btn-primary btn-danger" mwlConfirmationPopover [popoverTitle]="popoverTitle" [popoverMessage]="popoverMessage" placement="left" (cancel)="cancelClicked = true"  (confirm)="Delete(element.IDEvent)"><i class="fa fa-trash"></i></button>
                                             
                    </div>
                    </td>
                  </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
    


    </div>
    </div>
    </div>
  
    </div>
    </div>
    </div>
    </div>
    </div>


