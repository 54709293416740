import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './home/homepage/homepage.component';
import { InscripPageComponent } from './home/inscription/inscrip-page/inscrip-page.component';
import { NousContactezComponent } from './home/nous-contactez/nous-contactez.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashViewComponent } from './dashboard/dash-view/dash-view.component';
import { DashValiderComponent } from './dashboard/dash-valider/dash-valider.component';
import { DashRejeterComponent } from './dashboard/dash-rejeter/dash-rejeter.component';
import { LoginComponent } from './login/login.component';

import { AuthGuardService } from './guards/auth-guard.service';
import { WebiViewComponent } from './webinaire/webi-view/webi-view.component';
import { WebiCreateComponent } from './webinaire/webi-create/webi-create.component';
import { WebiUpdateComponent } from './webinaire/webi-update/webi-update.component';
import { UserViewComponent } from './user/user-view/user-view.component';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { UserUpdateComponent } from './user/user-update/user-update.component';
import { ProfiViewComponent } from './user-profil/profi-view/profi-view.component';
import { LoginResetComponent } from './login-reset/login-reset.component';
import { WebiHistoCreateComponent } from './webinaire/webi-histo-create/webi-histo-create.component';
import { WebiHistoViewComponent } from './webinaire/webi-histo-view/webi-histo-view.component';


const routes: Routes = [
  {path: '', component: HomepageComponent},
 /*{path: 'rencontres-web-hebdomadaires', component: InscripPageComponent},*/
 {path: 'nous-contacter', component: NousContactezComponent},
 /**/{path: 'dashboard', canActivate: [AuthGuardService], component: DashboardComponent},
 {path: 'webinair/participant/:id/:lien', canActivate: [AuthGuardService], component: DashViewComponent},
 {path: 'participant-valider', canActivate: [AuthGuardService], component: DashValiderComponent},
 {path: 'participant-rejeter', canActivate: [AuthGuardService], component: DashRejeterComponent},
 {path: 'webinaire', canActivate: [AuthGuardService], component: WebiViewComponent},
 {path: 'webinaire/create', canActivate: [AuthGuardService], component: WebiCreateComponent},
 {path: 'webinaire/update/:id', canActivate: [AuthGuardService], component: WebiUpdateComponent},
 {path: 'users', canActivate: [AuthGuardService], component: UserViewComponent}, /*data: {isAdmin:false}*/
 {path: 'user/create', canActivate: [AuthGuardService], component: UserCreateComponent},
 {path: 'user/update/:id', canActivate: [AuthGuardService], component: UserUpdateComponent},
 {path: 'webinar-historique', canActivate: [AuthGuardService], component: WebiHistoViewComponent},

 {path: 'user/profil', canActivate: [AuthGuardService], component: ProfiViewComponent},

 {path: 'user/reset', canActivate: [AuthGuardService], component: LoginResetComponent},

 {path: 'login', component: LoginComponent},

  {path: '', redirectTo: '', pathMatch: 'full' },
  {path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
