<!--app-dash-top></app-dash-top-->    
    <!--=================
        banner-part start
    ==================-->
    <section id="banner-part">
        <div class="overlay">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="banner-text">
                            <h2>{{theme}}</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item dot"><a routerLink="">Retour</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Nous contacter</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--=================
        banner-part end
    ==================-->
    <div class="spinner4" *ngIf="isLoadingResults">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>


    <section id="brand-part">
   
        <div class="section-title">
            <p>Découvrez les différents moyens de nous contacter en cas de problème.</p>
            <h2>Vous avez une question ? </h2>
           <a style="font-size: 22px; color: red;" [routerLink]="['']"> https://webinar.clinique-procrea.com/</a>
        </div>

         <div class="container line2">
             <div class="row">
                 <div class="col-lg-6 col-md-6">
                     <div class="brand-item mb">
                         <div class="title">
                             <h5><i class="fa fa-phone" aria-hidden="true"></i> PAR TÉLÉPHONE</h5>
                         </div>
                         <div class="brand-img">
                            <h3>{{webicontact1}}</h3>
                            <h3>{{webicontact2}}</h3>
                         </div>
                     </div>
                 </div>
                 <div class="col-lg-6 col-md-6">
                           <div class="brand-item mb">
                         <div class="title">
                             <h5><i class="fa fa-envelope" aria-hidden="true"></i> PAR MAIL</h5>
                         </div>
                         <div class="brand-img">
                            <a href="mailto:{{webiemail1}}"><h4 style="color: #0F2F44;"> {{webiemail1}} </h4></a>
                            <a href="mailto:{{webiemail2}}"> <h4 style="color: #0F2F44;">{{webiemail2}} </h4></a>
                         </div>
                     </div>
                 </div>
               
             </div>
         </div>
     </section>
