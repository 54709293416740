import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from '../service/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  isAdmin = false;

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) { }

 canActivate() {
    if (!this.authService.isLoggedIn) {
      /*alert('You are not allowed to view this page. You are redirected to login Page');*/
      if (localStorage.getItem('firstconnect') === '1') {
       
        this.router.navigate(['/user/reset']);
        return false;
      } else {
       
        this.router.navigate(['login']);
        return false;
      }
     /* this.router.navigate(['login']);
      return false;*/
    } else {
     /* if (localStorage.getItem('utilprofl') !== '1') {
      
        alert('je suis admin');
        this.isAdmin = true;
      } else {
       
        alert('simple utilisateur');
        this.isAdmin = false;
      }*/
      return true;
      
    }
    


    
  }

  /* canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isLoggedIn         
      .pipe(
        take(1),                              
        map((isLoggedIn: boolean) => {         
          if (!isLoggedIn){
            this.router.navigate(['/auth/login']);  
            return false;
          }
          return true;
        })
      );
  }
*/

}
