      <!--=================
        pricing-part start
    ==================-->
    <section id="pricing-part">
        <div class="container">
            <div class="section-title">
                <p>entry pass</p>
                <h2>Pricing &amp; Get Ticket</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 pr-0">
                    <div class="active-pricing slick-initialized slick-slider"><i class="fa fa-angle-up up slick-arrow" style="display: block;"></i>
                        <div class="slick-list draggable"><div class="slick-track" style="opacity: 1; width: 2555px; transform: translate3d(-1095px, 0px, 0px);"><div class="pricing-item slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1" style="width: 309px;">
                            <div class="single-pricing">
                                <h3>Regular Plan</h3>
                                <h2>$99.99</h2>
                                <ul>
                                    <li>A+ Class Seat</li>
                                    <li>Free WiFi</li>
                                    <li>Unlimited Coffee</li>
                                    <li>Lunch</li>
                                    <li>Workshop</li>
                                    <li>Stationary</li>
                                    <li>X</li>
                                    <li>X</li>
                                </ul>
                                <img class="shape1" src="assets/pricing-shape1.png" alt="pricing-shape1">
                                <img class="shape3" src="assets/pricing-shape3.png" alt="pricing-shape3">
                            </div>
                            <img class="shape2" src="assets/pricing-shape2.png" alt="pricing-shape2">
                        </div><div class="pricing-item slick-slide" data-slick-index="0" aria-hidden="true" tabindex="-1" style="width: 309px;">
                            <div class="single-pricing">
                                <h3>Regular Plan</h3>
                                <h2>$99.99</h2>
                                <ul>
                                    <li>A+ Class Seat</li>
                                    <li>Free WiFi</li>
                                    <li>Unlimited Coffee</li>
                                    <li>Lunch</li>
                                    <li>Workshop</li>
                                    <li>Stationary</li>
                                    <li>X</li>
                                    <li>X</li>
                                </ul>
                                <img class="shape1" src="assets/pricing-shape1.png" alt="pricing-shape1">
                                <img class="shape3" src="assets/pricing-shape3.png" alt="pricing-shape3">
                            </div>
                            <img class="shape2" src="assets/pricing-shape2.png" alt="pricing-shape2">
                        </div><div class="pricing-item slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" style="width: 309px;">
                            <div class="single-pricing">
                                <h3>Regular Plan</h3>
                                <h2>$99.99</h2>
                                <ul>
                                    <li>A+ Class Seat</li>
                                    <li>Free WiFi</li>
                                    <li>Unlimited Coffee</li>
                                    <li>Lunch</li>
                                    <li>Workshop</li>
                                    <li>Stationary</li>
                                    <li>X</li>
                                    <li>X</li>
                                </ul>
                                <img class="shape1" src="assets/pricing-shape1.png" alt="pricing-shape1">
                                <img class="shape3" src="assets/pricing-shape3.png" alt="pricing-shape3">
                            </div>
                            <img class="shape2" src="assets/pricing-shape2.png" alt="pricing-shape2">
                        </div><div class="pricing-item slick-slide slick-current slick-active" data-slick-index="2" aria-hidden="false" tabindex="0" style="width: 309px;">
                            <div class="single-pricing">
                                <h3>Regular Plan</h3>
                                <h2>$99.99</h2>
                                <ul>
                                    <li>A+ Class Seat</li>
                                    <li>Free WiFi</li>
                                    <li>Unlimited Coffee</li>
                                    <li>Lunch</li>
                                    <li>Workshop</li>
                                    <li>Stationary</li>
                                    <li>X</li>
                                    <li>X</li>
                                </ul>
                                <img class="shape1" src="assets/pricing-shape1.png" alt="pricing-shape1">
                                <img class="shape3" src="assets/pricing-shape3.png" alt="pricing-shape3">
                            </div>
                            <img class="shape2" src="assets/pricing-shape2.png" alt="pricing-shape2">
                        </div><div class="pricing-item slick-slide slick-cloned" data-slick-index="3" aria-hidden="true" tabindex="-1" style="width: 309px;">
                            <div class="single-pricing">
                                <h3>Regular Plan</h3>
                                <h2>$99.99</h2>
                                <ul>
                                    <li>A+ Class Seat</li>
                                    <li>Free WiFi</li>
                                    <li>Unlimited Coffee</li>
                                    <li>Lunch</li>
                                    <li>Workshop</li>
                                    <li>Stationary</li>
                                    <li>X</li>
                                    <li>X</li>
                                </ul>
                                <img class="shape1" src="assets/pricing-shape1.png" alt="pricing-shape1">
                                <img class="shape3" src="assets/pricing-shape3.png" alt="pricing-shape3">
                            </div>
                            <img class="shape2" src="assets/pricing-shape2.png" alt="pricing-shape2">
                        </div><div class="pricing-item slick-slide slick-cloned" data-slick-index="4" aria-hidden="true" tabindex="-1" style="width: 309px;">
                            <div class="single-pricing">
                                <h3>Regular Plan</h3>
                                <h2>$99.99</h2>
                                <ul>
                                    <li>A+ Class Seat</li>
                                    <li>Free WiFi</li>
                                    <li>Unlimited Coffee</li>
                                    <li>Lunch</li>
                                    <li>Workshop</li>
                                    <li>Stationary</li>
                                    <li>X</li>
                                    <li>X</li>
                                </ul>
                                <img class="shape1" src="assets/pricing-shape1.png" alt="pricing-shape1">
                                <img class="shape3" src="assets/pricing-shape3.png" alt="pricing-shape3">
                            </div>
                            <img class="shape2" src="assets/pricing-shape2.png" alt="pricing-shape2">
                        </div><div class="pricing-item slick-slide slick-cloned" data-slick-index="5" aria-hidden="true" tabindex="-1" style="width: 309px;">
                            <div class="single-pricing">
                                <h3>Regular Plan</h3>
                                <h2>$99.99</h2>
                                <ul>
                                    <li>A+ Class Seat</li>
                                    <li>Free WiFi</li>
                                    <li>Unlimited Coffee</li>
                                    <li>Lunch</li>
                                    <li>Workshop</li>
                                    <li>Stationary</li>
                                    <li>X</li>
                                    <li>X</li>
                                </ul>
                                <img class="shape1" src="assets/pricing-shape1.png" alt="pricing-shape1">
                                <img class="shape3" src="assets/pricing-shape3.png" alt="pricing-shape3">
                            </div>
                            <img class="shape2" src="assets/pricing-shape2.png" alt="pricing-shape2">
                        </div></div></div>
                        
                        
                    <i class="fa fa-angle-down down slick-arrow" style="display: block;"></i></div>
                </div>
                <div class="col-lg-8 col-md-6">
                    <div class="form-part">
                        <form action="http://themesitem.com/demos/html/eventoo/#">
                            <label class="heading">Giving your informations &amp; Getting the Ticket</label>
                            <input class="fname" type="text" placeholder="Your First Name">
                            <input class="lname" type="text" placeholder="Your Last Name">
                            <input type="email" placeholder="Your E-mail Address">
                            <input type="text" placeholder="Phone Number">
                            <select>
                                <option>Select a Plan</option>
                                <option value="1">Plan1</option>
                                <option value="2">Plan2</option>
                                <option value="3">Plan3</option>
                                <option value="4">Plan4</option>
                                <option value="5">Plan5</option>
                                <option value="6">Plan6</option>
                            </select>
                            <div class="row mx-0">
                                <div class="col-lg-6 p-0">
                                    <div class="payment">
                                        <h3>Select Payment Method</h3>
                                    </div>
                                </div>
                                <div class="col-lg-6 p-0">
                                    <div class="payment-img">
                                        <img src="assets/aclogo.png" alt="aclogo">
                                    </div>
                                </div>
                            </div>
                            <label class="check">I’ve raed all this carefully and I agree with all agreements.</label>
                            <button type="submit">Get the Ticket</button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="pricing-border"></div>
        </div>
    </section>
    <!--=================
        pricing-part end
    ==================-->