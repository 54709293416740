import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ToastrModule } from 'ngx-toastr';
import {DatePipe} from '@angular/common';

import {NgxPrintModule} from 'ngx-print';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './home/homepage/homepage.component';
import { HeaderComponent } from './tpl/header/header.component';
import { SliderComponent } from './tpl/slider/slider.component';
import { FestivePartComponent } from './tpl/festive-part/festive-part.component';
import { SpeakersComponent } from './tpl/speakers/speakers.component';
import { UpcomingPartComponent } from './tpl/upcoming-part/upcoming-part.component';
import { GaleriePartComponent } from './tpl/galerie-part/galerie-part.component';
import { TestimonialPartComponent } from './tpl/testimonial-part/testimonial-part.component';
import { PricingPartComponent } from './tpl/pricing-part/pricing-part.component';
import { SupportersPartComponent } from './tpl/supporters-part/supporters-part.component';
import { FooterComponent } from './tpl/footer/footer.component';
import { InscripHeaderComponent } from './home/inscription/inscrip-header/inscrip-header.component';
import { InscripFormulaireComponent } from './home/inscription/inscrip-formulaire/inscrip-formulaire.component';
import { InscripPageComponent } from './home/inscription/inscrip-page/inscrip-page.component';
import { NousContactezComponent } from './home/nous-contactez/nous-contactez.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IntropageComponent } from './intropage/intropage.component';
import { DashLeftMenuComponent } from './dashboard/dash-left-menu/dash-left-menu.component';
import { DashViewComponent } from './dashboard/dash-view/dash-view.component';
import { DashTopComponent } from './dashboard/dash-top/dash-top.component';
import { DashValiderComponent } from './dashboard/dash-valider/dash-valider.component';
import { DashRejeterComponent } from './dashboard/dash-rejeter/dash-rejeter.component';
import { LoginComponent } from './login/login.component';
import { WebiCreateComponent } from './webinaire/webi-create/webi-create.component';
import { WebiViewComponent } from './webinaire/webi-view/webi-view.component';
import { WebiUpdateComponent } from './webinaire/webi-update/webi-update.component';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { UserViewComponent } from './user/user-view/user-view.component';
import { UserUpdateComponent } from './user/user-update/user-update.component';
import { WebiHistoCreateComponent } from './webinaire/webi-histo-create/webi-histo-create.component';
import { WebiHistoViewComponent } from './webinaire/webi-histo-view/webi-histo-view.component';
import { WebiHistoUpdateComponent } from './webinaire/webi-histo-update/webi-histo-update.component';
import { ProfiCreateComponent } from './user-profil/profi-create/profi-create.component';
import { ProfiViewComponent } from './user-profil/profi-view/profi-view.component';
import { ProfiUpdateComponent } from './user-profil/profi-update/profi-update.component';
import { LoginResetComponent } from './login-reset/login-reset.component';


@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    HeaderComponent,
    SliderComponent,
    FestivePartComponent,
    SpeakersComponent,
    UpcomingPartComponent,
    GaleriePartComponent,
    TestimonialPartComponent,
    PricingPartComponent,
    SupportersPartComponent,
    FooterComponent,
    InscripHeaderComponent,
    InscripFormulaireComponent,
    InscripPageComponent,
    NousContactezComponent,
    DashboardComponent,
    IntropageComponent,
    DashLeftMenuComponent,
    DashViewComponent,
    DashTopComponent,
    DashValiderComponent,
    DashRejeterComponent,
    LoginComponent,
    WebiCreateComponent,
    WebiViewComponent,
    WebiUpdateComponent,
    UserCreateComponent,
    UserViewComponent,
    UserUpdateComponent,
    WebiHistoCreateComponent,
    WebiHistoViewComponent,
    WebiHistoUpdateComponent,
    ProfiCreateComponent,
    ProfiViewComponent,
    ProfiUpdateComponent,
    LoginResetComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,

    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    NgxPrintModule,
    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-bottom-full-width',
      preventDuplicates: true,
    }),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
    }),
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
