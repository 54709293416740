<app-dash-top></app-dash-top>



<div id="content" class="section-padding">
    <div class="container">
    <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-3 page-sidebar">
   <app-dash-left-menu></app-dash-left-menu>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-9">
    <div class="row page-content">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    
        <div class="inner-box">
            <div class="dashboard-box">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-8">
            <h2 class="dashbord-title" style="font-size: 22px;">{{Title}}
                <span class="badge badge-success" *ngIf="statutActive=='1'">ACTIVE</span>
                <span class="badge badge-warning" *ngIf="statutActive=='0'">DESACTIVE</span>
            </h2>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-12" style="text-align: right;">
                <button class="btn mr-2 btn-danger" (click)="getNavigation('webinaire', '')"><i class="fa fa-close"></i> FERMER</button>
                                    
                </div>
            </div>
            <div class="spinner4" *ngIf="isLoadingResults">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <div class="dashboard-wrapper">

                <form [formGroup]="validedForm" (ngSubmit)="saveForm(validedForm.value)">
                     <div class="form-row">
                        <div class="form-group col-md-12">
                          <label for="inputEmail4">Thème</label>
                          <input hidden name="IDEvent" type="text" formControlName="IDEvent" class="form-control is-invalid" id="inputEmail4">
                       
                          <input name="webi_theme" type="text" formControlName="webi_theme" class="form-control is-invalid" id="inputEmail4">
                        </div>
                        <div class="form-group col-md-12">
                            <label for="inputEmail4">Description du thème</label>
                            <textarea rows="6" name="webi_desciption" type="text" formControlName="webi_desciption" class="form-control is-invalid" id="inputEmail4"></textarea>
                          </div>

                          <div class="form-group col-md-6">
                            <label for="inputEmail4">Date 1</label>
                            <input name="webi_date1" type="date" formControlName="webi_date1" class="form-control is-invalid" id="inputEmail4">
                             </div>

                          <div class="form-group col-md-6">
                            <label for="inputEmail4">Date 2</label>
                            <input name="webi_date2" type="date" formControlName="webi_date2" class="form-control" id="inputEmail4">
                        </div>

                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Contact par téléphone</label>
                            <input name="webi_contact1" type="text" formControlName="webi_contact1" class="form-control is-invalid" id="inputEmail4" placeholder="Téléphone 1">
                            <input name="webi_contact2" type="text" formControlName="webi_contact2" class="form-control" id="inputEmail4" placeholder="Téléphone 2">
                             </div>

                          <div class="form-group col-md-6">
                            <label for="inputEmail4">Contact par mail</label>
                            <input name="webi_email1" type="text" formControlName="webi_email1" class="form-control is-invalid" id="inputEmail4" placeholder="Email 1">
                            <input name="webi_email2" type="text" formControlName="webi_email2" class="form-control" id="inputEmail4" placeholder="Email 2">
                            </div>

                            <div class="form-group col-md-6">
                                <label for="inputEmail4">Slogan d'incitation</label>
                                <input name="webi_slogan" type="text" formControlName="webi_slogan" class="form-control" id="inputEmail4" placeholder="Ex: SE PARLER SANS TABOUS EN TOUTE SÉCURITÉ">
                              </div>

                              <div class="form-group col-md-6">
                                <label for="inputEmail4">Lien de témoignage d'un(e) patient(e) - YOUTUBE</label>
                                <input name="webi_youtube" type="text" formControlName="webi_youtube" class="form-control" id="inputEmail4" placeholder="Ex: https://www.youtube.com/watch?v=JsBXjVzlSxE">
                              </div>

                        <div class="form-group col-md-4">
                          <label for="inputPassword4">Image d'affiche</label>
                          <input name="webi_image" formControlName="webi_image" type="file" class="form-control" id="inputPassword4" (change)="onFile($event)">
                        </div>
                        <div class="form-group col-md-4">
                        <a href="{{webimage}}"  target="_blank">
                            <img [src]="webimage" *ngIf="webimage" style="width: 143px;" alt="profile image" class="img-thumbnail">
                          </a>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <label for="inputEmail4">Lien d’invitation ZOOM</label>
                          <input name="webi_linezoom" type="text" formControlName="webi_linezoom" class="form-control is-invalid" id="inputEmail4">
                        </div>
               
                      </div> 

             
              
                    <div class="form-group">
                        <div class="tg-checkbox mt-3">
                            <div class="custom-control custom-checkbox" *ngIf="statutActive == '1'">
                            <input type="checkbox"  name="webi_etat" [ngModel]="statutActive" formControlName="webi_etat" class="custom-control-input" id="tg-priceoncall">
                            <label class="custom-control-label" for="tg-priceoncall">Activer la Rencontre Web Hebdomadaire en ligne.
                            </label>
                            </div>


                            <div class="custom-control custom-checkbox" *ngIf="statutActive == '0'">
                                <input type="checkbox"  name="webi_etat"  formControlName="webi_etat" class="custom-control-input" id="tg-priceoncall">
                                <label class="custom-control-label" for="tg-priceoncall">Activer la Rencontre Web Hebdomadaire en ligne.
                                </label>
                                </div>

                            </div>
 
                    </div>
                   
                    <div style="text-align: center;">
                        <button type="submit" class="btn mr-2 btn-success" [disabled]="!validedForm.valid"><i class="fa fa-check"></i> VALIDER</button>
                        <button class="btn btn-danger" (click)="getNavigation('webinaire', '')"><i class="fa fa-close"></i> FERMER</button>
                    </div>

                  </form>
                  

           
            </div>
            </div>


    </div>
  
    </div>
    </div>
    </div>
    </div>
    </div>


