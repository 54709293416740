import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { InscriptionService } from '../../service/inscription.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-webi-histo-view',
  templateUrl: './webi-histo-view.component.html',
  styleUrls: ['./webi-histo-view.component.css']
})
export class WebiHistoViewComponent implements OnInit {

  public date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  Usernom = localStorage.getItem('nom');
  Userprenoms = localStorage.getItem('prenoms');
  
  Title: string = 'Tableau de bord | Historique des Webinars';
  lienRetour: string = 'webinar-historique';
  imprimTitle: string = 'Historique Webinar';
       /*/////DELETE CONFIRMATION///////*/
       public popoverTitle: string = 'AVERTISSEMENT';
       public popoverMessage: string = 'Voulez-vous vraiment supprimer cette ligne?';
       public confirmClicked: boolean = false;
       public cancelClicked: boolean = false;
     /*/////END DELETE CONFIRMATION///////*/
  
  validedForm: FormGroup;
  tableData$: any = [];
  isLoadingResults = true;
  dashboard = false;
  totalInscrits: string;
  totalvalides: string;
  totalrejetes: string;
  idpays: string = '0';
  allpays: any = [];
  allwebinar: any = [];
  Printparticipant: any = [];
  pdebut: string;
  pfin: string;
  webitheme: string;
  
  displayedColumns: string[] = ['position', 'nomprenom', 'pays', 'sexe', 'contact', 'Action'];
   dataSource = new MatTableDataSource(this.tableData$);
   @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
    constructor(
      private router: Router,
      private datePipe: DatePipe,
      private inscriptionService: InscriptionService,
      private fb: FormBuilder) {
      this.validedForm = this.fb.group({
        ptc_Pays: [''],
        sexe: [''],
        IDEvent: ['', Validators.required],
        datedebut: [this.date, Validators.required],
        datefin: [this.date, Validators.required],
  
      });
     }
  
    ngOnInit(): void {
      this.pfin = '0';
      this.loadallwebinar();
      this.loadPayswebinarActif_histo(this.idpays);
      /*this.loadDataTable();*/
      this.dataSource.paginator = this.paginator;
      this.isLoadingResults = false;
    }
  
    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  
    /*  loadDataTable() {
      this.isLoadingResults = true;
      this.inscriptionService.getallPaticipant().subscribe(data => {
        this.tableData$ = data;
        this.totalInscrits = this.tableData$.results.total;
        this.dataSource.data = this.tableData$.results.liste;
        this.Printparticipant = this.tableData$.results.liste;
        this.loadtotalvalide();
        this.loadtotalrejete();
        this.isLoadingResults = false;
      });  
    }*/
  
    loadtotalvalide(IDEvent, datedebut, datefin) {
      this.inscriptionService.getallPaticipantValider_histo(1, IDEvent, datedebut, datefin).subscribe(data => {
        this.tableData$ = data;
        this.totalvalides = this.tableData$.results.total;
  
      });  /**/
    }
  
    loadtotalrejete(IDEvent, datedebut, datefin) {
      this.inscriptionService.getallPaticipantRejete_histo(2, IDEvent, datedebut, datefin).subscribe(data => {
        this.tableData$ = data;
        this.totalrejetes = this.tableData$.results.total;
      });  /**/
    }
  
    search(values){
      this.isLoadingResults = true;
      const ValidedFormData = new FormData();
      ValidedFormData.append('IDEvent', values.IDEvent);
      ValidedFormData.append('ptc_Pays', values.ptc_Pays);
      ValidedFormData.append('sexe', values.sexe);
      ValidedFormData.append('datefin', values.datefin);
      ValidedFormData.append('datedebut', values.datedebut);
      this.inscriptionService.searchPaticipant_histo(ValidedFormData).subscribe(data => {
        this.tableData$ = data;
        /*this.totalInscrits = this.tableData$.results.total;*/
        this.dataSource.data = this.tableData$.results.liste;
        this.loadtotalvalide(values.IDEvent, values.datedebut, values.datefin);
        this.loadtotalrejete(values.IDEvent, values.datedebut, values.datefin);
        this.dashboard = true;
        this.isLoadingResults = false;
        this.Printparticipant = this.tableData$.results.liste;
        this.webitheme = this.tableData$.results.liste[0].webi_theme;
        this.pdebut =  values.datedebut;
        this.pfin = values.datefin;
      });
    }
  
  
    Delete(){
  
    }
  
    getNavigation(link, id){
      if(id === ''){
          this.router.navigate([link]);
      } else {
          this.router.navigate([link + '/' + id]);
      }
  }
  
  loadPayswebinarActif_histo(id){
    this.inscriptionService.getPaticipantPays_histo(id).subscribe(
    paysData => {
      this.allpays = paysData.results.liste;
    
             }
    );
  }

  loadallwebinar(){
    this.inscriptionService.getallwebina().subscribe(
    webiData => {
      this.allwebinar = webiData.results.liste;
    
             }
    );
  }
  
  /*onChangeSelectedValue(event){
    const selectEl = event.target;
    const pays = selectEl.options[selectEl.selectedIndex].getAttribute('name-pays');
    const sexe = selectEl.options[selectEl.selectedIndex].getAttribute('name-sexe');
    this.seachParticipantbyPays(pays);
  } */
  
  
  
  /*seachParticipantbyPays(pays) {
    this.isLoadingResults = true;
    this.inscriptionService.getallPaticipantByPays(pays).subscribe(data => {
      this.tableData$ = data;
      this.totalInscrits = this.tableData$.results.total;
      this.dataSource.data = this.tableData$.results.liste;
      this.loadtotalvalide();
      this.loadtotalrejete();
      this.isLoadingResults = false;
    });  
  }
  */
  
  getNavigationRetun(link, id, lien){
    if(id === ''){
        this.router.navigate([lien]);
    } else {
        this.router.navigate([link + '/' + id + '/' + lien ]);
    }
  }
  

}
