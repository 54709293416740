import { Component, OnInit } from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../service/notification.service';
import { InscriptionService } from '../../service/inscription.service';

@Component({
  selector: 'app-dash-view',
  templateUrl: './dash-view.component.html',
  styleUrls: ['./dash-view.component.css']
})
export class DashViewComponent implements OnInit {

  isLoadingResults = true;
  Title: string = 'Profil du participant';
  lienRetour: string;

  validedForm: FormGroup;
  IDParticipation: any;
  IDEvent: any = 1;
  partcipantData: any;
  ptcetat: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private inscriptionService: InscriptionService,
    private notifyService: NotificationService,
    private actRoute: ActivatedRoute) {
      this.validedForm = this.fb.group({
        IDParticipation: [''],
        IDEvent: [''],
        ptc_civilite: ['', Validators.required],
        ptc_sexe: ['', Validators.required],
        ptc_smatrimoniale: ['', Validators.required],
        ptc_Nom: ['', Validators.required],
        ptc_Prenoms: ['', Validators.required],
        ptc_NomJeunefille: [''],
        ptc_Pays: ['Cote_d_Ivoire', Validators.required],
        ptc_Ville: ['', Validators.required],
        ptc_Datenaissance: [''],
        ptc_Email: ['', Validators.required],
        ptc_Mobile: ['', Validators.required],
        ptc_recevMessaeg: [''],
        ptc_date: [''],
        /*Util_id: [this.UserID], , Validators.required*/
      });
     }

  ngOnInit(): void {
    this.ptcetat ='0';
    this.IDParticipation = this.actRoute.snapshot.params['id'];
    this.lienRetour = this.actRoute.snapshot.params['lien'];
    this.loadPaticipantBy(this.IDParticipation);
  }

  
  loadPaticipantBy(id){
    this.validedForm.disable();
    this.inscriptionService.getPaticipantBy(id).subscribe(data => {
    this.partcipantData = data;
    /*console.log(this.custumerData.results[0].clt_Id);*/
    this.validedForm.controls['IDParticipation'].setValue(this.IDParticipation);
    this.validedForm.controls['ptc_civilite'].setValue(this.partcipantData.results.liste[0].ptc_civilite);
    this.validedForm.controls['ptc_sexe'].setValue(this.partcipantData.results.liste[0].ptc_sexe);
    this.validedForm.controls['ptc_smatrimoniale'].setValue(this.partcipantData.results.liste[0].ptc_smatrimoniale);
    this.validedForm.controls['ptc_Nom'].setValue(this.partcipantData.results.liste[0].ptc_Nom);
    this.validedForm.controls['ptc_Prenoms'].setValue(this.partcipantData.results.liste[0].ptc_Prenoms);
    this.validedForm.controls['ptc_NomJeunefille'].setValue(this.partcipantData.results.liste[0].ptc_NomJeunefille);
    this.validedForm.controls['ptc_Pays'].setValue(this.partcipantData.results.liste[0].ptc_Pays);
    this.validedForm.controls['ptc_Ville'].setValue(this.partcipantData.results.liste[0].ptc_Ville);
    this.validedForm.controls['ptc_Datenaissance'].setValue(this.partcipantData.results.liste[0].ptc_Datenaissance);
    this.validedForm.controls['ptc_Email'].setValue(this.partcipantData.results.liste[0].ptc_Email);
    this.validedForm.controls['ptc_Mobile'].setValue(this.partcipantData.results.liste[0].ptc_Mobile);
    this.validedForm.controls['ptc_recevMessaeg'].setValue(this.partcipantData.results.liste[0].ptc_recevMessaeg);
    this.ptcetat = this.partcipantData.results.liste[0].ptc_etat;
    this.isLoadingResults = false;
    });
    }

    saveForm(value){}

    getvalidate(id){
      this.isLoadingResults = true;
      const ValidedFormData = new FormData();
      ValidedFormData.append('IDParticipation', this.IDParticipation);
      ValidedFormData.append('ptc_etat', id);
      this.inscriptionService.updatePaticipant(ValidedFormData).subscribe(result => {
        if (result.success === true) {
       this.notifyService.showSuccess(result.message, 'SUCCES');
       this.isLoadingResults = false;
       this.getNavigation(this.lienRetour,'');
            }
            else {
              this.notifyService.showError(result.message, 'MESSAGE D\'ERREUR');
              this.isLoadingResults = false;
            }
   });
  }


  getNavigation(link, id){
    if(id === ''){
        this.router.navigate([link]);
    } else {
        this.router.navigate([link + '/' + id]);
    }
}

}
