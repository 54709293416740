    <!--=================
        header-part start
    ==================-->
    <header class="header-part sticky">
        <nav class="navbar navbar-expand-lg">
            <div class="container">
                <a class="navbar-brand" routerLink="">
                    <img src="assets/logo.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fa fa-bars" aria-hidden="true"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                       
                        <li class="nav-item">
                            <a class="nav-link" routerLink="nous-contacter">Nous Contacter</a>
                        </li>
                       
                    </ul>
                    <!--div class="Clinheader-btn mr-5">
                        <a href="https://clinique-procrea.com/" target="_blank">Clinique Procréa</a>
                    </div-->
                    <div class="header-btn">
                        <a href="https://clinique-procrea.com/" target="_blank">Clinique Procréa</a>
                    </div>
                </div>
            </div>
        </nav>
        <!--======= header-part end ======-->
    </header>
    <!--=================
        header-part end
    ==================-->