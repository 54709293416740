import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-part',
  templateUrl: './pricing-part.component.html',
  styleUrls: ['./pricing-part.component.css']
})
export class PricingPartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
