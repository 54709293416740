<app-dash-top></app-dash-top>



<div id="content" class="section-padding">
    <div class="container">
    <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-3 page-sidebar">
   <app-dash-left-menu></app-dash-left-menu>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-9">
    <div class="row page-content">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <div class="inner-box">
    <div class="dashboard-box">
    <h2 class="dashbord-title" style="font-size: 22px;">{{Title}}</h2>
    </div>
    <div class="dashboard-wrapper">

   



            <div class="spinner4" *ngIf="isLoadingResults">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-12">
                        <div class="well well-sm">
                            <div class="row">
                                
                                <div class="col-sm-12 col-md-12" style="text-align: center; color: red;">
                                    <h4> {{Usernom + ' '+ Userprenoms}} - Login: {{login}} - {{'Administrateur'}} - {{utilEmail}}</h4>
                                    

                                    <!-- Split button -->
                            
                                </div>

                                <!--div class="col-sm-3 col-md-3" style="padding-top: 20px;">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-danger" (click)="toggle()">
                                           
                                            {{buttonName}}
                                        </button>
                                                                      
                                    </div>
                                </div-->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    </div>






    <div class="inner-box" style="margin-top: 25px;">
        <div class="dashboard-box">
        <h2 class="dashbord-title" style="font-size: 22px;">Réinitialiser votre mot de passe </h2>
        </div>
        <div class="dashboard-wrapper">
    

                <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-12">
                            <div class="well well-sm">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <form [formGroup]="validedForm" (ngSubmit)="saveForm(validedForm.value)">
                                            <div class="form-row">
                                               <div class="form-group col-md-4">
                                                 <input name="oldmotdepasse" type="password" formControlName="oldmotdepasse" class="form-control is-invalid" placeholder="Mot de passe actuel">
                                               </div>
                                               <div class="form-group col-md-4">
                                                   <input name="newmotdepasse" type="password" formControlName="newmotdepasse" class="form-control is-invalid" placeholder="Nouveau mot de passe">
                                               </div>
                                               <div class="form-group col-md-4">
                                                <input name="renewmotdepasse" type="password" formControlName="renewmotdepasse" class="form-control is-invalid" placeholder="Confirmation du mot de passe">
                                            </div>                  
                                              
                                             </div>
                       
                                          
                                           <div style="text-align: center;">
                                               <button type="submit" class="btn mr-2 btn-success" [disabled]="!validedForm.valid"><i class="fa fa-check"></i> VALIDER</button>
                                               <button class="btn btn-danger" (click)="getNavigation('dashboard', '')"><i class="fa fa-close"></i> FERMER</button>
                                           </div>
                                         </form>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  
        
         
                     
    
          
    
        </div>
        </div>
        
    </div>
  
    </div>
    </div>
    </div>
    </div>
    </div>


    


