import { Component, OnInit , Inject } from '@angular/core';

/*import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  animal: string;
  name: string;
}*/

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  animal: string;
  name: string;

 /*  constructor(public dialog: MatDialog) { }

 openDialog(): void {
    const dialogRef = this.dialog.open(intropageDialog, {
      width: '250px',
      data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }*/

  ngOnInit(): void {
  }

}

/*
@Component({
  selector: 'intropage',
  templateUrl: 'intropage.html',
})
export class intropageDialog {

  constructor(
    public dialogRef: MatDialogRef<intropageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}*/
