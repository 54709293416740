import { Injectable } from '@angular/core';
/*************************************************/
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
/*import { LoginResponse } from '../_models/user';*/
import {ConfigurationService} from './configuration.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
   
 
  // API path
  url = this.configurationService.urlWebapi;
  private loggedIn = new BehaviorSubject<boolean>(false);
  isLoggedIn$: boolean;
  constructor(
    private router: Router,
    private http: HttpClient,
    private configurationService: ConfigurationService,
    
  ) { }
 
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
 
  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
 
 
  // Verify user credentials on server to get token
  loginForm(data): Observable<any> {return this.http.post<any>(this.url + 'webinar/login.php', data)
     .pipe(
        retry(2),
        catchError(this.handleError)
      ); /**/
  }
 

  // After login save token and other values(if any) in localStorage
  setUser(resp: any) {
    localStorage.setItem('firstconnect', resp.premierecnx);
    localStorage.setItem('login', resp.login);
    localStorage.setItem('access_token', resp.jwt);
    localStorage.setItem('UserID', resp.id);
    localStorage.setItem('nom', resp.nom);
    localStorage.setItem('prenoms', resp.prenoms);
    localStorage.setItem('expireAt', resp.expireAt);
    localStorage.setItem('password', resp.password);
    localStorage.setItem('email', resp.email);
    localStorage.setItem('utilprofl', resp.utilprofl);
    this.isLoggedIn$ = this.isLoggedIn;
    if (resp.premierecnx === '1') {
      this.router.navigate(['/user/reset']);
   } else {
    this.router.navigate(['/dashboard']);
   }
  }

  /*isAdminUser():boolean {
    if (localStorage.getItem('utilprofl') === '0') {
        return true; 
    }
    return false;
}*/
  
  /*getPermisData(UserProfil): Observable<any> {
    return this.http.get<any>(this.url + 'api/read/utilisateurpermission.php?id=' + UserProfil);
  }*/
 
  // Checking if token is set
  get isLoggedIn() {
    return localStorage.getItem('access_token') != null && localStorage.getItem('Prmierconnecio') !== '1';
  }/**/

  

  /*get isLoggedIn() {
    return this.loggedIn.asObservable(); 
  }*/
 
  // After clearing localStorage redirect to login screen
  logout() {
    localStorage.clear();
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }
 
 
  // Get data from server for Dashboard
  getData(data): Observable<any> {
    return this.http
      .post<any>(this.url + 'api/read/authentication.php', data, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }


  /*Premiere connexion*/
  PremiereConnexion(data): Observable<any> {
    return this.http.post<any>(this.url + 'webinar/premierecnx.php', data);
  }
 
}