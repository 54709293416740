<section id="banner-part">
    <div class="overlay">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="banner-text">
                        <h2>{{theme}}</h2>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item dot"><a routerLink="">Retour</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Administration</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--=================
    banner-part end
==================-->