import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonial-part',
  templateUrl: './testimonial-part.component.html',
  styleUrls: ['./testimonial-part.component.css']
})
export class TestimonialPartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
