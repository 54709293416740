import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../service/notification.service';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {


  isLoadingResults = true;
  Title: string = 'Edition d\'un utilisateur';
  lienRetour: string;
  statusbtn: boolean = false;
  validedForm: FormGroup;
  IDParticipation: any;
  IDEvent: any = 1;
  partcipantData: any;
  ptcetat: any;
  UserID = localStorage.getItem('UserID');
  public date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private notifyService: NotificationService,
    private datePipe: DatePipe,
    private actRoute: ActivatedRoute) {
      this.validedForm = this.fb.group({
        util_Nom: ['', Validators.required],
        util_Prenoms: ['', Validators.required],
        util_Email: ['', Validators.required],
        util_Login: ['', Validators.required],
        util_profl: ['', Validators.required],
        util_DateCrea: [''],
     
        Util_id: [this.UserID],
      });
     }

  ngOnInit(): void {
    this.isLoadingResults = false;
    this.IDParticipation = this.actRoute.snapshot.params['id'];
    this.lienRetour = this.actRoute.snapshot.params['lien'];
   /* this.loadPaticipantBy(this.IDParticipation);*/
  }

    saveForm(value){
      this.isLoadingResults = true;
      const ValidedFormData = new FormData();
      ValidedFormData.append('util_Nom', value.util_Nom);
      ValidedFormData.append('util_Prenoms', value.util_Prenoms);
      ValidedFormData.append('util_Email', value.util_Email);
      ValidedFormData.append('util_Login', value.util_Login);
      ValidedFormData.append('util_profl', value.util_profl);
      ValidedFormData.append('util_id', this.UserID);
      ValidedFormData.append('util_DateCrea', this.date);
    
      this.userService.createUser(ValidedFormData).subscribe(result => {
        if (result.success === true) {
       this.notifyService.showSuccess(result.message, 'SUCCES');
       this.isLoadingResults = false;
       this.validedForm.reset();
       this.validedForm.controls['util_profl'].setValue('');
      /* this.getNavigation(this.lienRetour,'');*/
            }
            else {
              this.notifyService.showError(result.message, 'MESSAGE D\'ERREUR');
              this.isLoadingResults = false;
            }
   });
  }


  onFile(event) {
    if (event.target.files.length > 0) {
      const fileannx = event.target.files[0];
      this.validedForm.get('webi_image').setValue(fileannx);
    }
  }

  getNavigation(link, id){
    if(id === ''){
        this.router.navigate([link]);
    } else {
        this.router.navigate([link + '/' + id]);
    }
}


}
