    <!--=================
        banner-part end
    ==================-->
    <!--=================
        pricing-part start
    ==================-->
    <section id="pricing-area">
        <div class="pricing-shape">
            <img src="./Inscription - Eventoo Html Template_files/event-shape1.png" alt="event-shape1">
        </div>
        <div class="pricing-shape2">
            <img src="./Inscription - Eventoo Html Template_files/event-shape2.png" alt="event-shape2">
        </div>
         <div class="container">
             <div class="row">
                 <div class="col-lg-4 col-md-4">
                     <div class="pricing-item">
                         <div class="single-pricing">
                             <h3>Regular Plan</h3>
                             <h2>$99.99</h2>
                             <ul>
                                 <li>A+ Class Seat</li>
                                 <li>Free WiFi</li>
                                 <li>Unlimited Coffee</li>
                                 <li>Lunch</li>
                                 <li>Workshop</li>
                                 <li>Stationary</li>
                                 <li>X</li>
                                 <li>X</li>
                             </ul>
                             <img class="shape1" src="./Inscription - Eventoo Html Template_files/pricing-shape1.png" alt="pricing-shape1">
                             <img class="shape3" src="./Inscription - Eventoo Html Template_files/pricing-shape3.png" alt="pricing-shape3">
                             <img class="shape4" src="./Inscription - Eventoo Html Template_files/pricing-shape4.png" alt="pricing-shape4">
                         </div>
                         <img class="shape2" src="./Inscription - Eventoo Html Template_files/pricing-shape2.png" alt="pricing-shape2">
                     </div>
                 </div>
                 <div class="col-lg-4 col-md-4">
                     <div class="pricing-item">
                         <div class="single-pricing">
                             <h3>Standard Plan</h3>
                             <h2>$199.99</h2>
                             <ul>
                                 <li>A+ Class Seat</li>
                                 <li>Free WiFi</li>
                                 <li>Unlimited Coffee</li>
                                 <li>Lunch</li>
                                 <li>Workshop</li>
                                 <li>Stationary</li>
                                 <li>Branding</li>
                                 <li>X</li>
                             </ul>
                             <img class="shape1" src="./Inscription - Eventoo Html Template_files/pricing-shape1.png" alt="pricing-shape1">
                             <img class="shape3" src="./Inscription - Eventoo Html Template_files/pricing-shape3.png" alt="pricing-shape3">
                             <img class="shape4" src="./Inscription - Eventoo Html Template_files/pricing-shape4.png" alt="pricing-shape4">
 
                         </div>
                         <img class="shape2" src="./Inscription - Eventoo Html Template_files/pricing-shape2.png" alt="pricing-shape2">
                     </div>
                 </div>
                 <div class="col-lg-4 col-md-4">
                     <div class="pricing-item">
                         <div class="single-pricing">
                             <h3>Premium Plan</h3>
                             <h2>$299.99</h2>
                             <ul>
                                 <li>A+ Class Seat</li>
                                 <li>Free WiFi</li>
                                 <li>Unlimited Coffee</li>
                                 <li>Lunch</li>
                                 <li>Workshop</li>
                                 <li>Stationary</li>
                                 <li>Branding</li>
                                 <li>Premium Plan</li>
                             </ul>
                             <img class="shape1" src="assets/images/brand/brand/pricing-shape1.png" alt="pricing-shape1">
                             <img class="shape3" src="assets/images/brand/brand/pricing-shape3.png" alt="pricing-shape3">
                             <img class="shape4" src="assets/images/brand/brand/pricing-shape4.png" alt="pricing-shape4">
                         </div>
                         <img class="shape2" src="assets/images/brand/brand/pricing-shape2.png" alt="pricing-shape2">
                     </div>
                 </div>
             </div>
         </div>
     </section>
     <!--=================
         pricing-part end
     ==================-->
     <!--=================
         booking-part start
     ==================-->
     <section id="pricing-part">
        <div class="booking-part">
         <div class="container">
             <div class="row">
                 <div class="col-lg-12">
                     <div class="form-part">
                         <form action="http://themesitem.com/demos/html/eventoo/pricing.html#">
                             <label class="heading f-heading">Giving your informations &amp; Getting the Ticket</label>
                             <input class="fname" type="text" placeholder="Your First Name">
                             <input class="lname" type="text" placeholder="Your Last Name">
                             <input type="email" placeholder="Your E-mail Address">
                             <input type="text" placeholder="Phone Number">
                             <select>
                                 <option>Select a Plan</option>
                                 <option value="1">Plan1</option>
                                 <option value="2">Plan2</option>
                                 <option value="3">Plan3</option>
                                 <option value="4">Plan4</option>
                                 <option value="5">Plan5</option>
                                 <option value="6">Plan6</option>
                             </select>
                             <div class="row mx-0">
                                 <div class="col-lg-6 p-0">
                                     <div class="payment">
                                         <h3>Select Payment Method</h3>
                                     </div>
                                 </div>
                                 <div class="col-lg-6 p-0">
                                     <div class="payment-img">
                                         <img src="assets/images/brand/brand/aclogo.png" alt="aclogo">
                                     </div>
                                 </div>
                             </div>
                             <label class="check">I’ve raed all this carefully and I agree with all agreements.</label>
                             <button type="submit">Get the Ticket</button>
                         </form>
                     </div>
                 </div>
             </div>
             <div class="pricing-border"></div>
         </div>
         </div>
     </section>
     <!--=================
         booking-part end
     ==================-->
     <!--=================
         sponsors-part start
     ==================-->
     <section id="sponsors-part">
         <div class="container">
             <div class="section-title">
                 <p>supporters</p>
                 <h2>Our Sponsors</h2>
             </div>
             <div class="brand">
                 <img src="assets/images/brand/brand-img1.png" alt="brand-img1.png">
                 <img src="assets/images/brand/brand-img2.png" alt="brand-img2.png">
                 <img src="assets/images/brand/brand-img3.png" alt="brand-img3.png">
                 <img src="assets/images/brand/brand-img4.png" alt="brand-img4.png">
                 <img src="assets/images/brand/brand-img5.png" alt="brand-img5.png">
                 <img src="assets/images/brand/brand-img6.png" alt="brand-img6.png">
                 <img src="assets/images/brand/brand-img7.png" alt="brand-img7.png">
                 <img src="assets/images/brand/brand-img8.png" alt="brand-img8.png">
                 <img src="assets/images/brand/brand-img9.png" alt="brand-img9.png">
             </div>
         </div>
     </section>
     <!--=================
         sponsors-part end
     ==================-->
     <!--=================
         footer-part start
     ==================-->