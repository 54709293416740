import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../service/notification.service';
import { WebinarService } from '../../service/webinar.service';

@Component({
  selector: 'app-webi-update',
  templateUrl: './webi-update.component.html',
  styleUrls: ['./webi-update.component.css']
})
export class WebiUpdateComponent implements OnInit {

  isLoadingResults = true;
  Title: string = 'Edition d\'une Rencontre Web Hebdomadaire';
  lienRetour: string;

  validedForm: FormGroup;
  IDParticipation: any;
  IDEvent: any;
  eventData: any;
  ptcetat: any;
  webimage: any;
  statutActive: string;
  UserID = localStorage.getItem('UserID');
  public date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private webinarService: WebinarService,
    private notifyService: NotificationService,
    private datePipe: DatePipe,
    private actRoute: ActivatedRoute) {
      this.validedForm = this.fb.group({
        webi_theme: ['', Validators.required],
        webi_desciption: ['', Validators.required],
        webi_date1: ['', Validators.required],
        webi_date2: [''],
        webi_contact1: ['', Validators.required],
        webi_contact2: [''],
        webi_email1: ['', Validators.required],
        webi_email2: [''],
        webi_slogan: [''],
        webi_youtube: [''],
        webi_image: [''],
        webi_linezoom: ['', Validators.required],
        webi_etat: [''],
        webi_datecreate: [''],
        Util_id: [this.UserID],
        IDEvent:[this.IDEvent, Validators.required],
      });
     }

  ngOnInit(): void {
    this.isLoadingResults = false;
    this.IDEvent = this.actRoute.snapshot.params['id'];
   /* this.lienRetour = this.actRoute.snapshot.params['lien'];*/
    this.loadwebinarBy(this.IDEvent);
  }

  
 loadwebinarBy(id){
    this.isLoadingResults = true;
    this.webinarService.getWebinarbyId(id).subscribe(data => {
    this.eventData = data;
    this.validedForm.controls['IDEvent'].setValue(this.IDEvent);
    this.validedForm.controls['webi_theme'].setValue(this.eventData.results.liste[0].webi_theme);
    this.validedForm.controls['webi_desciption'].setValue(this.eventData.results.liste[0].webi_desciption);
    this.validedForm.controls['webi_date1'].setValue(this.eventData.results.liste[0].webi_date1);
    this.validedForm.controls['webi_date2'].setValue(this.eventData.results.liste[0].webi_date2);
    this.validedForm.controls['webi_contact1'].setValue(this.eventData.results.liste[0].webi_contact1);
    this.validedForm.controls['webi_contact2'].setValue(this.eventData.results.liste[0].webi_contact2);
    this.validedForm.controls['webi_email1'].setValue(this.eventData.results.liste[0].webi_email1);
    this.validedForm.controls['webi_email2'].setValue(this.eventData.results.liste[0].webi_email2);
    this.validedForm.controls['webi_slogan'].setValue(this.eventData.results.liste[0].webi_slogan);
    this.validedForm.controls['webi_youtube'].setValue(this.eventData.results.liste[0].webi_youtube);
    this.validedForm.controls['webi_linezoom'].setValue(this.eventData.results.liste[0].webi_linezoom);
    this.statutActive = this.eventData.results.liste[0].webi_etat;
    if (!this.eventData.results.liste[0].webi_image) {this.webimage = '';}
    else{this.webimage = this.webinarService.url + this.eventData.results.liste[0].webi_image;}
  
   /* this.validedForm.controls['webi_image'].setValue(this.eventData.results.liste[0].webi_image);
    this.ptcetat = this.eventData.results.liste[0].ptc_etat;*/
    this.isLoadingResults = false;
    });
    }

    saveForm(value){
      this.isLoadingResults = true;
      const ValidedFormData = new FormData();
    /*alert(value.webi_etat);*/
   
      ValidedFormData.append('webi_theme', value.webi_theme);
      ValidedFormData.append('webi_desciption', value.webi_desciption);
      ValidedFormData.append('webi_date1', value.webi_date1);
      ValidedFormData.append('webi_date2', value.webi_date2);
      ValidedFormData.append('webi_contact1', value.webi_contact1);
      ValidedFormData.append('webi_contact2', value.webi_contact2);
      ValidedFormData.append('webi_email1', value.webi_email1);
      ValidedFormData.append('webi_email2', value.webi_email2);
      ValidedFormData.append('webi_slogan', value.webi_slogan);
      ValidedFormData.append('webi_youtube', value.webi_youtube);
      ValidedFormData.append('webi_image', this.validedForm.get('webi_image').value);
      ValidedFormData.append('webi_linezoom', value.webi_linezoom);
      /*ValidedFormData.append('webi_etat', value.webi_etat);*/
      if(value.webi_etat === true || value.webi_etat == '1'){
        ValidedFormData.append('webi_etat', '1');
      }

      if(value.webi_etat == '' || value.webi_etat === false){
        ValidedFormData.append('webi_etat', '0');
      }/**/
     /* else{
        ValidedFormData.append('webi_etat', this.statutActive);
      }*/
     
      ValidedFormData.append('util_id', this.UserID);
      ValidedFormData.append('IDEvent', this.IDEvent);
    
      this.webinarService.updatetWebinar(ValidedFormData).subscribe(result => {
        if (result.success === true) {
       this.notifyService.showSuccess(result.message, 'SUCCES');
       this.isLoadingResults = false;
       this.validedForm.reset();
       this.getNavigation('/webinaire','');
            }
            else {
              this.notifyService.showError(result.message, 'MESSAGE D\'ERREUR');
              this.isLoadingResults = false;
            }
   });
  }

  onFile(event) {
    if (event.target.files.length > 0) {
      const fileannx = event.target.files[0];
      this.validedForm.get('webi_image').setValue(fileannx);
    }
  }


  getNavigation(link, id){
    if(id === ''){
        this.router.navigate([link]);
    } else {
        this.router.navigate([link + '/' + id]);
    }
}

}
