<section id="sponsors-part">
    <div class="container">
        <div class="section-title">
            <p>supporters</p>
            <h2>Our Sponsors</h2>
        </div>
        <div class="brand">
            <img src="assets/brand-img1.png" alt="brand-img1.png">
            <img src="assets/brand-img2.png" alt="brand-img2.png">
            <img src="assets/brand-img3.png" alt="brand-img3.png">
            <img src="assets/brand-img4.png" alt="brand-img4.png">
            <img src="assets/brand-img5.png" alt="brand-img5.png">
            <img src="assets/brand-img6.png" alt="brand-img6.png">
            <img src="assets/brand-img7.png" alt="brand-img7.png">
            <img src="assets/brand-img8.png" alt="brand-img8.png">
            <img src="assets/brand-img9.png" alt="brand-img9.png">
        </div>
    </div>
</section>
<!--=================
    sponsors-part end
==================-->