<app-dash-top></app-dash-top>



<div id="content" class="section-padding">
    <div class="container">
    <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-3 page-sidebar">
   <app-dash-left-menu></app-dash-left-menu>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-9">
    <div class="row page-content">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    
        <div class="inner-box">
            <div class="dashboard-box">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <h2 class="dashbord-title" style="font-size: 22px;">{{Title}}</h2>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-12" style="text-align: right;">
                <button class="btn mr-2 btn-danger" (click)="getNavigation('users', '')"><i class="fa fa-close"></i> FERMER</button>
                                    
                </div>
            </div>
            <div class="spinner4" *ngIf="isLoadingResults">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <div class="dashboard-wrapper">

                <form [formGroup]="validedForm" (ngSubmit)="saveForm(validedForm.value)">
                     <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="inputEmail4">Nom</label>
                          <input name="util_Nom" type="text" formControlName="util_Nom" class="form-control is-invalid" id="inputEmail4">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Prénoms</label>
                            <input name="util_Prenoms" type="text" formControlName="util_Prenoms" class="form-control is-invalid" id="inputEmail4">
                         </div>

                          <div class="form-group col-md-4">
                            <label for="inputEmail4">Email</label>
                            <input name="util_Email" type="email" formControlName="util_Email" class="form-control is-invalid" id="inputEmail4">
                             </div>

                          <div class="form-group col-md-4">
                            <label for="inputEmail4">Login</label>
                            <input name="util_Login" type="text" formControlName="util_Login" class="form-control is-invalid" id="inputEmail4">
                        </div>
                        <div class="form-group col-md-4">
                          <label for="inputEmail4">Profil</label>
                          <select  formControlName="util_profl" class="form-control is-invalid" style="width: 100%;">
                            <option selected="selected" value="">---- CHOISIR ----</option> 
                            <option value="1">
                                {{'utilisateur' | uppercase}}
                            </option>
                            <option value="0">
                              {{'Administrateur' | uppercase}}
                          </option>
                        </select> 
                      </div>

                 
                      </div>

                   
                    <div style="text-align: center;">
                        <button type="submit" class="btn mr-2 btn-success" [disabled]="!validedForm.valid"><i class="fa fa-check"></i> VALIDER</button>
                        <button class="btn btn-danger" (click)="getNavigation('users', '')"><i class="fa fa-close"></i> FERMER</button>
                    </div>
                  </form>
                  

           
            </div>
            </div>


    </div>
  
    </div>
    </div>
    </div>
    </div>
    </div>