import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../service/notification.service';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-profi-view',
  templateUrl: './profi-view.component.html',
  styleUrls: ['./profi-view.component.css']
})
export class ProfiViewComponent implements OnInit {

  Title: string = 'Mon Profil';
lienRetour: string = 'dashboard';
validedForm: FormGroup;

Usernom = localStorage.getItem('nom');
Userprenoms = localStorage.getItem('prenoms');
UserID = localStorage.getItem('UserID');
login = localStorage.getItem('login');
utilMdp = localStorage.getItem('password');
utilEmail = localStorage.getItem('email');

isLoadingResults = true;

public show:boolean = false;
public buttonName:any = 'MODIFIER MON MOT DE PASSE';
constructor(
  private router: Router,
  private fb: FormBuilder,
  private userService: UserService,
  private notifyService: NotificationService,
  private datePipe: DatePipe,
  private actRoute: ActivatedRoute) {
    this.validedForm = this.fb.group({
      login: [''],
      oldmotdepasse: ['', Validators.required],
      newmotdepasse: ['', Validators.required],
      renewmotdepasse: ['', Validators.required],
      Util_id: [this.UserID],
    });
   }

  ngOnInit(): void {
    this.isLoadingResults = false;
  }

  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show){
      this.buttonName = 'FERMER';
    } else{
      this.buttonName = 'MODIFIER MON MOT DE PASSE';
  }
  

}

saveForm(value){
  this.isLoadingResults = true;
  const ValidedFormData = new FormData();
  ValidedFormData.append('login', this.login);
  ValidedFormData.append('oldmotdepasse', value.oldmotdepasse);
  ValidedFormData.append('newmotdepasse', value.newmotdepasse);
  ValidedFormData.append('renewmotdepasse', value.renewmotdepasse);
  ValidedFormData.append('util_id', this.UserID);

  this.userService.resetPassword(ValidedFormData).subscribe(result => {
    if (result.success === true) {
   this.notifyService.showSuccess(result.message, 'SUCCES');
   this.isLoadingResults = false;
   this.validedForm.reset();
        }
        else {
          this.notifyService.showError(result.message, 'MESSAGE D\'ERREUR');
          this.isLoadingResults = false;
        }
});
}


getNavigation(link, id){
  if(id === ''){
      this.router.navigate([link]);
  } else {
      this.router.navigate([link + '/' + id]);
  }
}

}
