    <!--=================
        banner-part start
    ==================-->
    <section id="banner-part">
        <div class="overlay">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="banner-text">
                            <h2>L'infertilité, la Fécondation In Vitro et le regard des autres</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item dot"><a href="">Retour</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Rencontres Web Hebdomadaires</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--=================
        banner-part end
    ==================-->