    <!--=================
        festive-part start
    ==================-->
    <section id="festive-part">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="festive-left">
                   
                        <div>
                            <div class="festive-img" data-slick-index="1" aria-hidden="true" tabindex="0" role="tabpanel" id="slick-slide01" aria-describedby="slick-slide-control01" style="width: 445px;">
                                <a class="venobox vbox-item" data-autoplay="true" data-gall="myGallery" href="assets/images/imgx/rencontres-web-hebdomadaires-13mai.jpg">
                   
                                <img class="festive" src="assets/images/imgx/rencontres-web-hebdomadaires-13mai.jpg" alt="rencontres-web-hebdomadaires"></a>
                            </div>
                  
                     </div>
                    </div>
                </div>
               
                <div class="col-lg-7"> 
                    <div class="festive-right">
                        <div class="text-img">
                            <p style="margin-bottom: 15px;">13 Mai</p>
                            <p>15 Mai</p>

                            <h2>20</h2>
                            <h2>20</h2>
                            
                        </div>
                        <div class="festive-text">
                            <h2>Le couple n'arrive pas à avoir d'enfants. Comment le vivez-vous?</h2>
                            <p class="fest-para1">
                                Parfois, la volonté de concevoir un enfant est si forte qu’elle en devient obsessive. Alors, l’homme a le sentiment de n’être perçu que comme un géniteur. Et sa femme en oublie qu’il est aussi le mari, l’amant, l’ami.
                                Pour certaines femmes, à la tristesse de ne pas tomber enceinte s'ajoutela culpabilité de priver leur conjoint du bonheur de devenir père. Quand c'est l'homme qui a un problème de fertilité, c'est lui qui culpabilise, d'autant que c'est quand même la femme qui devra suivre les traitements s'il y en a. Alors ils s’enferment dans une bulle négative. Mauvaise humeur, larmes et vagues de désespoir rythment leur quotidien. Et cette situation génère bien des tensions au sein du couple.
                            </p>
                            <!--p class="fest-para2">
                                La Fécondation in Vitro est une technique de laboratoire qui nous permet de féconder un ovule avec un spermatozoïde en dehors de l’utérus.
                                Cette technique a pour objectif de recréer en laboratoire les différentes étapes de la fécondation naturelle tout en maximisant les chances (recueil de plusieurs ovocytes) et en les optimisant (sélection des spermatozoïdes et des embryons). Cette mise en œuvre est opérée par une équipe pluridisciplinaire constituée de gynécologues, biologistes, infirmiers, secrétaires et psychologues.
                            </p-->
                            <div class="location">
                                <div class="address">
                                    <img src="assets/images/media/zoom-web-application.png" alt="zoom-web-application">
                                    <table>
                                        <tr>
                                            <td style="font-size: 18px;" colspan="2">Via l'application ZOOM</td>
                                        </tr>
                                        <tr>
                                            <td style="width: 24px;"><img src="assets/images/application/TDpp7fh8s7a.svg" alt="Android" style="width: 24px;"/></td>
                                            <td><a href="https://play.google.com/store/apps/details?id=us.zoom.videomeetings" target="_blank" style="color:#1EBEA5; margin: 0px;">Android</a></td>
                                        </tr>
                                        <tr>
                                            <td style="width: 24px;"><img src="assets/images/application/QBqjMTNuxPz.svg" alt="iPhone" /></td>
                                            <td><a href="https://apps.apple.com/us/app/zoom-cloud-meetings/id546505307" target="_blank" style="color:#1EBEA5;">iPhone</a></td>
                                        </tr>
                                        <tr>
                                            <td style="width: 24px;"><img src="assets/images/application/w9G2-B0wRmZ.svg" alt="Mac ou PC Windows" /></td>
                                            <td style="color:#1EBEA5;"> <a href="https://apps.apple.com/us/app/id546505307" target="_blank" style="color:#1EBEA5;">Mac</a> ou <a href="https://zoom.us/client/latest/ZoomInstaller.exe" target="_blank" style="color:#1EBEA5;">PC Windows</a></td>
                                        </tr>
                                    </table>
                                                          
                                </div>
                                <div class="address add-ml">
                                    <img src="assets/phone.png" alt="phone">
                                    
                                    <p class="num">+225 45 55 11 47  +225 45 55 11 49</p>
                                </div>
                            </div>
                            <div>
                                  </div>  
                                                                                  
                              
                           
                        
                        </div>
                      </div>  

     
                
                </div>
               
            </div>
           
        </div>



    </section>

    <div class="col-lg-12" style="padding-bottom: 10px;">
    <div class="container">
        <div class="row">
                  
            <div class="footer-menu2">
                <ul>    
                    <li>
                        <a class="venobox vbox-item" data-autoplay="true" data-vbtype="video" href="https://www.youtube.com/watch?v=FeB0hjW-tog"><i class="fa fa-play" aria-hidden="true"></i> Comment installer ZOOM sur son ordinateur en 2 MIN</a></li>
                    <li>
                        <a class="venobox vbox-item" data-autoplay="true" data-vbtype="video" href="https://www.youtube.com/watch?v=ASwxEerYcjY"><i class="fa fa-play" aria-hidden="true"></i> Zoom Installation sur iPad & Iphone</a></li>
                    <li>
                        <a class="venobox vbox-item" data-autoplay="true" data-vbtype="video" href="https://www.youtube.com/watch?v=2GqqTyyEybc"><i class="fa fa-play" aria-hidden="true"></i> Zoom Installation sur téléphone Android</a></li>
                     </ul>
            </div>
            </div>
        </div>
    </div>
    <!--=================
        festive-part end
    ==================-->