import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webi-histo-create',
  templateUrl: './webi-histo-create.component.html',
  styleUrls: ['./webi-histo-create.component.css']
})
export class WebiHistoCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
