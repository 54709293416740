<!--
  upcoming-part start
  ==================-->
  <section id="upcoming-part">

    <div class="section-title">
        <p>fututure events</p>
        <h2>Upcoming Events</h2>
    </div>
    <div class="container">
        <div class="row" id="boxscroll" tabindex="1" style="overflow: hidden; outline: none;">
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img1.png" alt="event-img1">
                    </div>
                    <div class="event-text">
                        <h3>Wordpress Day Long Bootcamp</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img2.png" alt="event-img2">
                    </div>
                    <div class="event-text">
                        <h3>Graphic Design Seminar for 3 Days</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img3.png" alt="event-img3">
                    </div>
                    <div class="event-text">
                        <h3>laravel Framework Workshop (Day Long)</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img4.png" alt="event-img4">
                    </div>
                    <div class="event-text">
                        <h3>UI/UX Bootcamp - 2020</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img1.png" alt="event-img1">
                    </div>
                    <div class="event-text">
                        <h3>Wordpress Day Long Bootcamp</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img2.png" alt="event-img2">
                    </div>
                    <div class="event-text">
                        <h3>Graphic Design Seminar for 3 Days</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img3.png" alt="event-img3">
                    </div>
                    <div class="event-text">
                        <h3>laravel Framework Workshop (Day Long)</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img4.png" alt="event-img4">
                    </div>
                    <div class="event-text">
                        <h3>UI/UX Bootcamp - 2020</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img1.png" alt="event-img1">
                    </div>
                    <div class="event-text">
                        <h3>Wordpress Day Long Bootcamp</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img2.png" alt="event-img2">
                    </div>
                    <div class="event-text">
                        <h3>Graphic Design Seminar for 3 Days</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img3.png" alt="event-img3">
                    </div>
                    <div class="event-text">
                        <h3>laravel Framework Workshop (Day Long)</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img4.png" alt="event-img4">
                    </div>
                    <div class="event-text">
                        <h3>UI/UX Bootcamp - 2020</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img1.png" alt="event-img1">
                    </div>
                    <div class="event-text">
                        <h3>Wordpress Day Long Bootcamp</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img2.png" alt="event-img2">
                    </div>
                    <div class="event-text">
                        <h3>Graphic Design Seminar for 3 Days</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img3.png" alt="event-img3">
                    </div>
                    <div class="event-text">
                        <h3>laravel Framework Workshop (Day Long)</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="event-item">
                    <div class="event-img">
                        <img src="assets/event-img4.png" alt="event-img4">
                    </div>
                    <div class="event-text">
                        <h3>UI/UX Bootcamp - 2020</h3>
                        <div class="event-location">
                            <div class="event-add">
                                <div class="loc-img">
                                    <img src="assets/event-location.png" alt="event-location">
                                </div>
                                <div class="loc-text">
                                    <p>7382 Shirley Garden Avenue North-South Ozone Park,</p>
                                </div>
                            </div>
                            <div class="event-add shape">
                                <div class="loc-img">
                                    <img src="assets/event-phn.png" alt="event-phn">
                                </div>
                                <div class="loc-text">
                                    <p class="num">+880 1678965412 +880 1632145678</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="event-btn">
                        <a href="http://themesitem.com/demos/html/eventoo/#">Book Your Seat</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--=================
    upcoming-part end
==================-->