<aside>
    <div class="sidebar-box">
    <div class="user">
    <figure>
    <a href="#"><img src="assets/images/imgx/avatar.png" width="152px" alt=""></a>
    </figure>
    <div class="usercontent">
    <h3>{{Usernom+' '+ Userprenoms}}</h3>
    <h4>Administrateur</h4>
    </div>
    </div>
    <nav class="navdashboard">
    <ul>
    <li>
    <a [routerLink]="['/dashboard']" routerLinkActive="active"  href="#">
        <i class="fa fa-tachometer" aria-hidden="true"></i>
    <span>Tableau de bord</span>
    </a>
    </li>
    <li>
    <a [routerLink]="['/participant-valider']" routerLinkActive="active">
        <i class="fa fa-users" aria-hidden="true"></i>
    <span>Inscrits validés </span>
    </a>
    </li>
    <li>
    <a [routerLink]="['/participant-rejeter']" routerLinkActive="active">
        <i class="fa fa-users" aria-hidden="true"></i>
    <span>Inscrits rejetés</span>
    </a>
    </li>
    <li>
    <a [routerLink]="['/webinaire']" routerLinkActive="active">
     <i class="fa fa-podcast" aria-hidden="true"></i>
    <span>Gestion des Webinars</span>
    </a>
</li>

    <li>
        <!---->
    <a [routerLink]="['/webinar-historique']" routerLinkActive="active">
        <i class="fa fa-podcast" aria-hidden="true" style="color: red;"></i>
       <span>Historique des Webinars</span>
       </a>
    </li>
    <li>
    <a [routerLink]="['/users']" routerLinkActive="active" *ngIf="utilprofl!='1'">
    <i class="fa fa-user" aria-hidden="true"></i>
    <span>Gestion des utilisateurs</span>
    </a>
    </li>
    <li>
    <a [routerLink]="['/user/profil']" routerLinkActive="active">
    <i class="fa fa-user-circle" aria-hidden="true"></i>
    <span>Mon profil</span>
    </a>
    </li>
    <!--li>
    <a href="privacy-setting.html">
    <i class="lni-star"></i>
    <span>Privacy Settings</span>
    </a>
    </li-->
    <li>
    <a [routerLink]="['/login']">
        <i class="fa fa-sign-out" aria-hidden="true"></i>
    <span>Se Déconnecter</span>
    </a>
    </li>
    </ul>
    </nav>
    </div>

    </aside>

