import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { WebinarService } from '../../service/webinar.service';
import { NotificationService } from '../../service/notification.service';

@Component({
  selector: 'app-webi-view',
  templateUrl: './webi-view.component.html',
  styleUrls: ['./webi-view.component.css']
})
export class WebiViewComponent implements OnInit {


  Title: string = 'Gestion des Webinars - Rencontres Web Hebdomadaires';
  lienRetour: string = 'participant-rejeter';

  /*/////DELETE CONFIRMATION///////*/
  public popoverTitle: string = 'AVERTISSEMENT';
  public popoverMessage: string = 'Voulez-vous vraiment supprimer cette ligne?';
  public confirmClicked: boolean = false;
  public cancelClicked: boolean = false;
/*/////END DELETE CONFIRMATION///////*/


tableData$: any = [];
isLoadingResults = true;
totalInscrits: string;
totalvalides: string;
totalrejetes: string;
totalWebinar: string;

displayedColumns: string[] = ['position', 'theme', 'datacreate', 'etat', 'Action'];
dataSource = new MatTableDataSource(this.tableData$);
@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

constructor(
  private router: Router,
  private webinarService: WebinarService,
  private notificationService: NotificationService) { }

ngOnInit(): void {
 this.loadDataTable();
this.dataSource.paginator = this.paginator;
}

applyFilter(event: Event) {
 const filterValue = (event.target as HTMLInputElement).value;
 this.dataSource.filter = filterValue.trim().toLowerCase();
}

 loadDataTable() {
 this.isLoadingResults = true;
 this.webinarService.getallWebinar().subscribe(data => {
   this.tableData$ = data;
   this.totalWebinar = this.tableData$.results.total;
   this.dataSource.data = this.tableData$.results.liste.reverse();
   this.isLoadingResults = false;
   /*this.loadtotalvalide();
   this.loadtotalrejete();*/
  
 });  /**/
}

/*loadtotalvalide() {
 this.inscriptionService.getallPaticipantValider(1).subscribe(data => {
   this.tableData$ = data;
   this.totalvalides = this.tableData$.results.total;

 });  
}
*/
/*loadtotalrejete() {
 this.inscriptionService.getallPaticipantRejete(2).subscribe(data => {
   this.tableData$ = data;
   this.totalrejetes = this.tableData$.results.total;
   this.isLoadingResults = false;
 }); 
}
 */


Delete(ID) {
  this.isLoadingResults = true;
  this.webinarService.deleteWebinar(ID).subscribe(data => {
    if (data.success === true) {
      this.notificationService.showSuccess(data.message, 'SUCCES');
      this.loadDataTable();
      this.isLoadingResults = false;
    }else {
      this.notificationService.showError(data.message, 'MESSAGE D\'ERREUR');
      this.isLoadingResults = false;
    }
     });
    }

getNavigation(link, id){
 if(id === ''){
     this.router.navigate([link]);
 } else {
     this.router.navigate([link + '/' + id]);
 }
}


getNavigationRetun(link, id, lien){
  if(id === ''){
      this.router.navigate([lien]);
  } else {
      this.router.navigate([link + '/' + id + '/' + lien ]);
  }
}




}
