<app-dash-top></app-dash-top>


<section id="brand-part" style="padding: 30px;">
   
    <div class="col-md-6 offset-md-3 mt-0">
        <!--div class="alert alert-info">
            Username: test<br />
            Password: test
        </div-->
        <div class="card">
            <h4 class="card-header">Connexion</h4>
            <div class="card-body">
                <div class="spinner4" *ngIf="isLoadingResults">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
                <form [formGroup]="ValidedForm" (ngSubmit)="login(ValidedForm.value)">
             

                    <div class="form-group">
                        <input type="text" formControlName="login" class="form-control"/>
                        <label class="control-label" for="input-login">Identifiant</label><i class="bar"></i>
                        <div class="alert-danger" *ngIf="!ValidedForm.controls['login'].valid && (ValidedForm.controls['login'].touched)">
                            <div [hidden]="!ValidedForm.controls['login'].errors.required">
                                    Le Login est obligatoire
                                  </div>  
                    </div>
                    </div>
                    <div class="form-group">
                        <input formControlName="motdepasse" class="form-control"  type="password" id="input-password">
                        <label class="control-label" for="input-password">Mot de Passe</label><i class="bar"></i>
                        <div class="alert-danger" *ngIf="!ValidedForm.controls['motdepasse'].valid && (ValidedForm.controls['motdepasse'].touched)">
                            <div [hidden]="!ValidedForm.controls['motdepasse'].errors.required">
                                    Le Mot de Passe est obligatoire
                                  </div>  
                    </div>
                    </div>

                  
                    <button [disabled]="!ValidedForm.valid" type="submit" class="btn btn-login float-button-light">Connexion</button>
                   
                </form>
            </div>
        </div>
    </div>
    
 </section>
